import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import * as languages from './langs';

const resources = languages;

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en",
    fallbackLng: "en", // use en if detected lng is not available
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    },
   
  });
  if(localStorage.getItem('i18nextLng') === null) {
    localStorage.setItem('i18nextLng', 'en');
}
  i18n.changeLanguage(localStorage.getItem('i18nextLng'));
export default i18n;