import React from 'react';
import './styles.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './index.scss';
import 'react-toastify/dist/ReactToastify.css';

const Spinner = (props) => {
    return (
        <div className="spinnerContainer" style={props.style}>
            <div className="loader"></div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
       lang: state.lang.language
    };
};

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                
            },
            dispatch
        )
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Spinner);