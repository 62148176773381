import Api from "../../config/api";
import errorParse from '../lib/error';

function getMessageList(params) {
    const res = Api.get('/getMessagesList', {params}).then((response)=>{
        let data = response.data;
        return data;
    }).catch((error)=>{
        return errorParse(error);
    });
    return res;
}

function registerMessage(params) {
    const res = Api.post('/addMessages', params).then((response)=>{
        let data = response.data;
        return data;
    }).catch((error)=>{
        return errorParse(error);
    });
    return res;
}

function deleteMessageRequest(params) {
    const res = Api.get('/deleteMessage',{params}).then((response)=>{
        let data = response.data;
        return data;
    }).catch((error)=>{
        return errorParse(error);
    });
    return res;
}

function publishUnpublishRequest(params) {
    const res = Api.get('/is_published',{params}).then((response)=>{
        let data = response.data;
        return data;
    }).catch((error)=>{
        return errorParse(error);
    });
    return res;
}

export const MessageService = { 
    getMessageList,
    registerMessage,
    deleteMessageRequest,
    publishUnpublishRequest
}
