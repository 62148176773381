import ReportActionTypes from './actionTypes';

const INITIAL_STATE = {
   weather_history_loading: false,
   weather_history: [],

   watch_count_loading: false,
   watch_count: [],
}

export default function(state = INITIAL_STATE, action){
    switch(action.type){
        case ReportActionTypes.WEATHER_HISTORY_REPORT_REQUEST:
            return {
                ...state,
                weather_history_loading: true,
                weather_history: [],
            }
        case ReportActionTypes.WEATHER_HISTORY_REPORT_SUCCESS:
            return {
                ...state,
                weather_history_loading: false,
                weather_history: action.weather_history
            }
        case ReportActionTypes.WEATHER_HISTORY_REPORT_FAILURE:
            return {
                ...state,
                weather_history_loading: false,
                weather_history: []
            }

        case ReportActionTypes.WATCH_COUNT_REPORT_REQUEST:
            return {
                ...state,
                watch_count_loading: true,
                watch_count: [],
            }
        case ReportActionTypes.WATCH_COUNT_REPORT_SUCCESS:
            return {
                ...state,
                watch_count_loading: false,
                watch_count: action.watch_count
            }
        case ReportActionTypes.WATCH_COUNT_REPORT_FAILURE:
            return {
                ...state,
                watch_count_loading: false,
                watch_count: []
            }
        default: 
            return state;
    }
}