const Menu= [
    {
        "menu": "season",
        "subMenu": [
            {
                "label": "Season",
                "id": "season",
            }
        ]
    },
    {
        "menu": "grow_area",
        "subMenu": [
            {
                "label": "Grow Area",
                "id": "grow_area",
            }
        ]
    },
    {
        "menu": "scale_value",
        "subMenu": [
            {
                "label": "Scale Value",
                "id": "scale_value",
            }
        ]
    },
    {
        "menu": "guide",
        "subMenu": [
            {
                "label": "Guide",
                "id": "guide",
            }
        ]
    },
    {
        "menu": "message",
        "subMenu": [
            {
                "label": "Message",
                "id": "message",
            }
        ]
    },
    {
        "menu": "report",
        "subMenu": [
            {
                "label": "Weather",
                "id": "weather"
            },
            {
                "label": "Video Watch Count",
                "id": "video_watch_count"
            }
        ]
    },
    {
        "menu": "setting",
        "subMenu": [
            {
                "label": "Organization",
                "id": "organization"
            },
            {
                "label": "Role",
                "id": "add_role"
            },
            {
                "label": "Language",
                "id": "language"
            }
        ]
    },
    {
        "menu": "crop",
        "subMenu": [
            {
                "label": "Crop",
                "id": "detail"
            },
            {
                "label": "General",
                "id": "general"
            },
            {
                "label": "Variety",
                "id": "variety"
            },
            {
                "label": "Characteristics",
                "id": "characteristics"
            },
            {
                "label": "Indicator",
                "id": "indicator"
            },
            {
                "label": "Fertilizer",
                "id": "fertilizer"
            },
            {
                "label": "Pest and Diseases",
                "id": "pest_diseases",
                    
            },
            {
                "label": "Activity Media",
                "id": "activity_media" 
            },
            {
                "label": "Crop Calendars",
                "id": "crop_calendars"
            }
        ]
    },
    {
        "menu": "activity_names_and_categories",
        "subMenu": [
            {
                "label": "Activity names and Categories",
                "id": "activity_names_and_categories",
            }
        ]
    }
]

export default Menu;