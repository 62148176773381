import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { languageUpdate } from '../trans/actions';
import './styles.css';

const Select = props => {
    return (
        <div className="TextBoxContainer">
            <div className="leftLabelContainer">
                <span>{props.label}</span>
            </div>

            <div className="rightLabelContainer">
                <select className="selectInput">
                    <option disabled>Choose</option>
                    {
                        props.options &&
                            props.options.map((options,key)=>{
                                return (
                                    <option key={key}>
                                        {options}
                                    </option>
                                )
                            })
                    }
                </select>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
       lang: state.lang.language
    };
};

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                languageUpdate
            },
            dispatch
        )
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Select);