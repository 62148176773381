import ActivityActionTypes from './actionTypes';

export function registerActivity(params) {
    return {
        type: ActivityActionTypes.ACTIVITY_REGISTER_REQUEST,
        params
    }
}

export function getCategory() {
    return {
        type: ActivityActionTypes.CATEGORY_LIST_REQUEST
    }
}

export function activityList() {
    return {
        type: ActivityActionTypes.ACTIVITY_LIST_REQUEST
    }
}

export function deleteActivityCategory(activity_id) {
    return {
        type: ActivityActionTypes.DELETE_ACTIVITY_REQUEST,
        activity_id
    }
}

export function activityListRequest(category){
    return {
        type: ActivityActionTypes.ACTIVITY_SORTING_LIST_REQUEST,
        category
    }
}

export function removeActivities(){
    return {
        type: ActivityActionTypes.REMOVE_ACTIVITIES
    }
}

export function updateActivityStatus(params) {
    return {
        type: ActivityActionTypes.UPDATE_ACTIVITY_STATUS_REQUEST,
        params
    }
}