import React from 'react';
import Header from '../../../common/header';
import '../../crop/view/index.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TextBox from '../../../common/textBox';

const Index = (props) => {
    const textChange = (name, text) => {

    }
    return (
        <div className="characteristicsContainer">
            <Header header={false} />

            <div className="generalBody">
                <div className="formCard" style={{flexDirection: 'column', width: '100%'}}>
                    <div className="flexDirectionColumn"> 
                        <TextBox label="Language Code" name="language_code" onTextChange={textChange}  />
                        <TextBox label="Organization" name="organization" onTextChange={textChange}  />
                    </div>

                    <table style={{width: "60%"}} className="tableContainer" cellSpacing="0">
                        <thead>
                            <tr>
                                {/* <th><input type="checkbox"/></th> */}
                                <th>Language Code</th>
                                <th>Organization</th>
                                <th>
                                    <span>Edit</span> 
                                    {/* <span>
                                        <img 
                                             src={require('../../../images/edit_image.png')} 
                                             alt="edit" 
                                             style={{width: 15,height: 15,marginLeft: 3}}
                                        />
                                    </span> */}
                                </th>
                                <th>
                                    <span>Delete</span> 
                                    {/* <span>
                                        <img 
                                             src={require('../../../images/delete.png')} 
                                             alt="edit" 
                                             style={{width: 15,height: 15,marginLeft: 3}}
                                        />
                                    </span> */}
                                </th>
                            </tr>
                        </thead> 
                        <tbody>
                        
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
       
    };
};
function mapDispatchtoProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                
            },
            dispatch
        )
    };
}

export default connect(
    mapStateToProps,
    mapDispatchtoProps
)(Index);