const SeasonActionTypes={
    SEASON_LIST_REQUEST: 'season_list_request',
    SEASON_LIST_SUCCESS: 'season_list_success',
    SEASON_LIST_FAILURE: 'season_list_failure',
    SEASON_SELECT_REQUEST: 'season_select_request',
    REMOVE_SELECTED_SEASON: 'remove_selected_season',

    ADD_SEASON_REQUEST: 'add_season_request',
    ADD_SEASON_SUCCESS: 'add_season_success',
    ADD_SEASON_FAILURE: 'add_season_failure',

    DELETE_SEASON_REQUEST: 'delete_season_request',
    DELETE_SEASON_SUCCESS: 'delete_season_success',
    DELETE_SEASON_FAILURE: 'delete_season_failure',
}
export default SeasonActionTypes;