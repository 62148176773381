import Api from "../../config/api";
import errorParse from '../lib/error';

function seasonRequest(params) {
    const res = Api.get('/seasonList',{params}).then((response)=>{
        let data = response.data;
        return data;
    }).catch((error)=>{
        return errorParse(error);
    });
    return res;
}

function addSeasonRequest(params) {
    const res = Api.post('/addSeasonKeys', params).then((response)=>{
        let data = response.data;
        return data;
    }).catch((error)=>{
        return errorParse(error);
    });
    return res;
}

function deleteSeasonRequest(params) {
    const res = Api.get('/deleteSeasonKeys', {params}).then((response)=>{
        let data = response.data;
        return data;
    }).catch((error)=>{
        return errorParse(error);
    });
    return res;
}

export const SeasonService = { 
    seasonRequest,
    addSeasonRequest,
    deleteSeasonRequest
}
