import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { languageUpdate } from '../trans/actions';
import './styles.css';

const TextArea = props => {
    return (
        <div className="TextBoxContainer">
            <div className="leftLabelContainer leftLabelContainer2">
                <span>{props.label}</span>
            </div>
 
            <div className="rightTextAreaLabelContainer">
                {
                    props.error &&
                        <span className="errorText">{props.error}</span>
                }
                {
                    <span>{props.value1}</span>
                }
                {
                    <textarea 
                        id={props.id}
                        className="textAreaInput"
                        defaultValue={props.value}
                        rows="9"
                        onChange={(e)=>{
                            props.onTextChange1 ? 
                                props.onTextChange1(props.name,e.target.value)
                            :
                                props.onTextChange(props.name,e.target.value)
                            }
                        }
                    />
                }
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
       lang: state.lang.language
    };
};

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                languageUpdate
            },
            dispatch
        )
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TextArea);