import SeasonActionTypes from './actionTypes';

export function seasonListRequest(data){
    return {
        type: SeasonActionTypes.SEASON_LIST_REQUEST,
        data
    }
}

export function selectSeason(selected_season){
    return {
        type: SeasonActionTypes.SEASON_SELECT_REQUEST,
        selected_season
    }
}

export function removeSelectedSeason(){
    return {
        type: SeasonActionTypes.REMOVE_SELECTED_SEASON
    }
}

export function addSeasonRequest(data){
    return {
        type: SeasonActionTypes.ADD_SEASON_REQUEST,
        data
    }
}

export function seasonDeleteRequest(data){
    return {
        type: SeasonActionTypes.DELETE_SEASON_REQUEST,
        data
    }
}