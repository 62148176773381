import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Header from '../../../../common/header';
import { bindActionCreators } from 'redux';
import '../../../index.scss';
import Breadcrumb from '../../../../common/breadcrumb';

const CropCalendarMedia = (props) => {
    const [videoVisibility, setVideoVisibility] = useState(false);

    const getLangContent = (language) => {
        let data =  props.language_list.findIndex((tempData)=>{
            return tempData.locale.includes(language) === true
        })
        if(data !== -1){
            return data;
        }
        else{
            return 0;
        }
    }

    const getLanguageContent = (language) => {
        let data =  props.language_list.filter((tempData)=>{
            return tempData.locale.includes(language) === true
        })
        if(data.length > 0){
            return data[0].locale;
        }
        else{
            return "en_EN";
        }
    }

    useEffect(()=>{
        setVideoVisibility(videoVisibility);
    },[videoVisibility]);

    const displayVideoClick = () => {
        setVideoVisibility(!videoVisibility);
    }

    var langContent = getLangContent(props.lang);
    var languageContent = getLanguageContent(props.lang);

    return (
        <div>
            <Header header={false} />

            <Breadcrumb displayVideo={true} videoVisibility={videoVisibility} onDisplayVideoClick={displayVideoClick} />

            <div className="generalBody" style={{paddingTop: 35}}> 
                <div className="formCard" style={{flexDirection: 'column'}}> 
                    <div className="videoContainer">
                        {
                            props.selected_crop_activity.activity[0].media.map((tempData,key1)=>{
                                return (
                                    "media_text" in tempData &&
                                        tempData.media_text.filter((temp_media_data)=>{
                                            return languageContent === temp_media_data.locale
                                        }).map((temp_data,key)=>{
                                            return (
                                                <div key={key} className="videoPlayerContainer">
                                                    <div className="videoTitleContainer">
                                                        <span className="titleTextContainer">
                                                            {key1 + 1}. {temp_data.title}
                                                        </span>
                                                    </div>

                                                    <div style={{position:'relative'}}> 
                                                    {
                                                        
                                                        !temp_data.link || temp_data.link !== "NULL" || temp_data.link !== null ?
                                                            <div className="videoPlayer">
                                                                {
                                                                    videoVisibility === false ?
                                                                        <img 
                                                                            style={{position:'absolute',top:'35%',left:'45%'}} 
                                                                            src={require('../../../../images/Shape.png')} 
                                                                        /> 
                                                                    :
                                                                        <video className="videoPlayer" controls>
                                                                            <source 
                                                                                src={`https://cloud.auxfin.com/index.php/s/VQlsukNTIXyyPB7/download?path=%2F`+tempData.crop_id+`&files=`+tempData.crop_id+'_rn_KI_activities_'+tempData.activity_id+'_'+tempData.id+'.m4v'} 
                                                                                type="video/mp4" 
                                                                            />
                                                                        </video>
                                                                }
                                                            </div>
                                                        :
                                                            <div className="videoPlayer"></div>
                                                    }
                                                    </div>

                                                    <div className="descriptionContainer">
                                                        <span className="descriptionText">
                                                            {temp_data.description}
                                                        </span>
                                                    </div> 
                                                </div>
                                            )
                                        })
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        selected_crop_activity: state.cropCalendar.selected_crop_calendar_media,
        //language1: state.lang.language === 'en' ? 'en_EN' : state.lang.language === 'fr' ? 'fr_FR' : 'rn_KI',
        //language: state.lang.language === 'en' ? 0 : state.lang.language === 'fr' ? 1 : 2,
        lang: state.lang.language,
        language_list: state.lang.language_list
    };
};
function mapDispatchtoProps(dispatch) {
    return {
        actions: bindActionCreators(
            {   
                
            },
            dispatch
        )
    };
}

export default connect(
    mapStateToProps,
    mapDispatchtoProps
)(CropCalendarMedia);