import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { languageUpdate } from '../trans/actions';
import './styles.css';
import './index.scss';

const TextBox = (props) => {
    return (
        <div className={"placeholder" in props ? "TextBoxContainerWithoutPadding" : "TextBoxContainer"}>  
            <div 
                className={props.fertilizer === true 
                    ? "leftFertilizerContainer" 
                    : props.fertilizer_mix === true 
                    ?   "leftLabelContainer3"
                    : "leftLabelContainer2 leftLabelContainer"
                }  
                style={props.type === "min_max_value" ? {flex: 0} : {}}
            >
               {
                    props.type === "fertilizer" ?
                        props.fomi1 === true &&
                            <span>{props.label}</span>
                    :
                        <span>
                        {
                            props.label
                        }
                        {
                            props.unit && 
                                <span style={{marginLeft: "10px"}}>({props.unit})</span>
                        }
                        </span>
               }
            </div>

            <div 
                className={
                    props.fertilizer === true 
                    ? "rightFertilizerContainer" 
                    : props.fertilizer_mix === true 
                    ? "rightFertilizerContainer3"
                    : "rightLabelContainer"
                }
            >
                {

                    props.type === "min_max" ?
                        <>
                            <input 
                                id={props.id1}
                                type="text"
                                className="textBoxInput"
                                defaultValue={props.value1}
                                style={{width: 50,marginRight: 10}}
                                onChange={(e)=>{props.onTextChange(props.name1,e.target.value)}}
                            />
                            <span style={{marginRight: 10}}>
                                {!props.label1 ? "Min" : ""}
                            </span>

                            {
                                props.label1 && 
                                    <span style={{marginRight: 10}}>
                                        {props.label1}
                                    </span>
                            }
                            <input 
                                id={props.id2}
                                type="text"
                                className="textBoxInput"
                                defaultValue={props.value2}
                                style={props.label1 === "Of Unit" ? {width: 150,marginRight: 10}: {width: 50,marginRight: 10}}
                                onChange={(e)=>{props.onTextChange(props.name2,e.target.value)}}
                            />
                            {
                                !props.label1 ?
                                    <div className="flexColumn">
                                        <span>Max {props.text1}</span>
                                        <span>{props.text2}</span>
                                    </div>
                                :
                                    ""
                            }
                        </>
                    :
                        props.type === "min_max_temp_value" ?
                            <>
                                <input 
                                    id={props.id1}
                                    type="text"
                                    className="textBoxInput"
                                    defaultValue={props.value1}
                                    style={{width: 50,marginRight: 10}}
                                    onChange={(e)=>{props.onTextChange(props.name1,e.target.value)}}
                                />
                                <span style={{marginRight: 10}}>
                                    {!props.label1 ? "Min" : ""}
                                </span>

                                {
                                    props.label1 && 
                                        <span style={{marginRight: 10}}>
                                            {props.label1}
                                        </span>
                                }
                                <div style={{display: "flex",flexDirection: 'column'}}>
                                    {
                                        <span>{props.value}</span>
                                    }
                                    <input 
                                        id={props.id2}
                                        type="text"
                                        className="textBoxInput"
                                        defaultValue={props.value2}
                                        style={props.label1 === "Of Unit" ? {width: 150,marginRight: 10}: {width: 50,marginRight: 10}}
                                        onChange={(e)=>{props.onTextChange(props.name2,e.target.value)}}
                                    />
                                    {
                                        !props.label1 ?
                                            <div className="flexColumn">
                                                <span>Max {props.text1}</span>
                                                <span>{props.text2}</span>
                                            </div>
                                        :
                                            ""
                                    }
                                </div>
                            </>
                    : 
                        props.type === "activity" ?
                            <input 
                                id={props.id}
                                type="text"
                                className="textBoxInput"
                                defaultValue={props.value}
                                pattern="[0-9]*"
                                style={{width: 20}}
                                onInput={props.handleChange}
                                onChange={(e)=>{props.onTextChange(props.name,e.target.value,props.key1,props.seasonKey)}}
                            />
                        :
                        props.type === "fertilizer" ?
                            props.isFirst === true ?
                                <div style={{display: 'flex',flexDirection: 'column'}}>
                                    <span>{props.unit}</span>
                                    <input 
                                        onChange={(e)=>{
                                            props.onTextChange1 ? 
                                                props.onTextChange1(props.name,e.target.value)
                                            :
                                                props.onTextChange(props.name,e.target.value)
                                            }
                                        }
                                        id={props.id}
                                        type="text"
                                        className="textBoxInput"
                                        defaultValue={props.value}
                                        style={props.fomiContent === true ? {width: 220,marginRight: 10} : {width: 75,marginRight: 10}}
                                    />
                                </div>
                            :
                                <>
                                    <input 
                                        onChange={(e)=>{
                                            props.onTextChange1 ? 
                                                props.onTextChange1(props.name,e.target.value)
                                            :
                                                props.onTextChange(props.name,e.target.value)
                                            }
                                        }
                                        id={props.id}
                                        type="text"
                                        className="textBoxInput"
                                        defaultValue={props.value}
                                        style={props.fomiContent === true 
                                            ? {width: 220,marginRight: 10} 
                                            : {width: 75,marginRight: 10}
                                        }
                                    />
                                </>

                    :
                        props.type === "min_max_value" ?
                            <>
                                <input 
                                    id={props.id1}
                                    type="text"
                                    className="textBoxInput"
                                    defaultValue={props.value}
                                    style={{width: 55,marginRight: 10}}
                                    onChange={(e)=>{props.onTextChange(props.name,e.target.value)}}
                                />
                                <span style={{marginRight: 10}}>Min</span>
                                <input 
                                    id={props.id2}
                                    type="text"
                                    className="textBoxInput"
                                    defaultValue={props.value1}
                                    style={{width: 80,marginRight: 10}}
                                    onChange={(e)=>{props.onTextChange(props.name1,e.target.value)}}
                                />
                                <span style={{marginRight: 10}}>Max</span>
                                <input 
                                    id={props.id3}
                                    type="text"
                                    className="textBoxInput"
                                    defaultValue={props.value2}
                                    style={{width: 55,marginRight: 10}}
                                    onChange={(e)=>{props.onTextChange(props.name2,e.target.value)}}
                                />
                                <span>Value</span> 
                            </>
                        :
                            <div style={{display: "flex",flexDirection: 'column'}}>
                                {
                                    props.error &&
                                        <span className="errorText">{props.error}</span>
                                }
                                {
                                    <span>{props.value1}</span>
                                }
                                <div style={{display: "flex",flexDirection: 'row'}}>
                                    <input
                                        id={props.id}
                                        placeholder={"placeholder" in props ? props.placeholder : ""}
                                        onChange={(e)=>{
                                            props.onTextChange ? 
                                                props.onTextChange(props.name,e.target.value)
                                            :
                                                props.onTextChange1(props.name,e.target.value)
                                            }
                                        }
                                        type="text"
                                        defaultValue={props.value}  
                                        className={`
                                            ${props.disabled === true ? 'textBoxInput disabled' : 'textBoxInput'}
                                            ${props.type === "small" && 'smallText'}
                                            ${props.disabled === true && 'disabledText'}
                                            ${props.name === "Comms" && 'nameText'}
                                            ${props.fertilizer_mix === true && 'fertilizerMix'}
                                            ${props.type === "latLng" && 'latLng'}
                                        `}
                                        disabled={props.disabled}
                                    />
                                    {
                                        props.unit1 && 
                                            <span style={{marginLeft: "10px"}}>{props.unit1}</span>
                                    }
                                </div>
                            </div>
                }
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
       lang: state.lang.language
    };
};

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                languageUpdate
            },
            dispatch
        )
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TextBox);