import UsageActionTypes from './actionTypes';

export function listUsageRequest(data){
    return {
        type: UsageActionTypes.USAGE_LIST_REQUEST,
        data
    }
}

export function deleteUsageRequest(data){
    return {
        type: UsageActionTypes.USAGE_DELETE_REQUEST,
        data
    }
}

export function particularUsageDetailRequest(data){
    return {
        type: UsageActionTypes.PARTICULAR_USAGE_REQUEST,
        data
    }
}

export function registerUsageRequest(params){
    return {
        type: UsageActionTypes.USAGE_REGISTER_REQUEST,
        params
    }
}

export function getCategoryListRequest(){
    return {
        type: UsageActionTypes.USAGE_CATEGORY_LIST_REQUEST
    }
}

export function versionListRequest(){
    return {
        type: UsageActionTypes.VERSION_LIST_REQUEST
    }
}