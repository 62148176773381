import React, {useState} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { languageUpdate } from '../trans/actions';
import './styles.css';

const Switch = props => {
    const [selected, setSelected] = useState(props.selected);
    var Selected = props.selected;

    return (
        <div className="TextBoxContainer">
            <div className="leftLabelContainer">
                <span>{props.label}</span>
            </div>

            <div className="rightLabelContainer">
                <label className="switch">
                    <input
                        id={props.id}
                        type="checkbox" 
                        defaultChecked={selected}
                        onChange={()=>{  
                            setSelected(!props.selected)
                            props.onTextChange(
                                props.name,
                                !Selected
                            )
                        }}
                        checked={props.checked}
                    />
                    <span className="slider round">
                        <span className="on">YES</span>
                        <span className="off">NO</span>
                    </span>
                </label>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
       lang: state.lang.language
    };
};

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                languageUpdate
            },
            dispatch
        )
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Switch);