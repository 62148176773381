import React from 'react';
import { Route } from 'react-router-dom';
import Index from './view/index';
import WatchCountReport from './view/watchCountReport';

const Report = props => {
    return (
       <>
            <Route 
                path={`${props.match.path}/weather`}
                component={Index}
            />

            <Route 
                path={`${props.match.path}/video_watch_count`}  
                component={WatchCountReport}
            />
       </>
    )
}

export default Report;