const OrganizationActionTypes={
    ORGANIZATION_LIST_REQUEST: 'organization_list_request',
    ORGANIZATION_LIST_SUCCESS: 'organization_list_success',
    ORGANIZATION_LIST_FAILURE: 'organization_list_failure',
    
    ORGANIZATION_SELECT_REQUEST: 'organization_select_request',
    ORGANIZATION_SELECT_SUCCESS: 'organization_select_success',

    ORGANIZATION_OFFLINE_REQUEST: 'organization_offine_request',
    ORGANIZATION_OFFLINE_SUCCESS: 'organization_offine_success',

    ORGANIZATION_ADD_REQUEST: 'organization_add_request',
    ORGANIZATION_ADD_SUCCESS: 'organization_add_success',

    ORGANIZATION_DELETE_REQUEST: 'organization_delete_request',
    ORGANIZATION_DELETE_SUCCESS: 'organization_delete_success',

    REMOVE_SELECTED_ORGANISATION: 'remove_selected_organisation',

    COUNTRY_LIST_REQUEST: 'country_list_request',
    COUNTRY_LIST_SUCCESS: 'country_list_success',

    ORGANISATION_LIST_REQUEST: 'organisation_list_request',
    ORGANISATION_LIST_SUCCESS: 'organisation_list_success',

    ORGANISATION_LOCALE_REQUEST: 'organisation_locale_request',
    ORGANISATION_LOCALE_SUCCESS: 'organisation_locale_success',

    ORAGNISATION_MAPPING_LIST_REQUEST: 'organisation_mapping_list_request',
    ORAGNISATION_MAPPING_LIST_SUCCESS: 'organisation_mapping_list_success',

    COUNTRY_REQUEST: 'country_request',
    COUNTRY_SUCCESS: 'country_success',

    ORGANIZATION_REQUEST: 'organization_request',
    ORGANIZATION_SUCCESS: 'organization_success',
}
export default OrganizationActionTypes;