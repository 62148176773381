const MessageActionTypes={
    MESSAGE_LIST_REQUEST: 'message_list_request',
    MESSAGE_LIST_SUCCESS: 'message_list_success',

    MESSAGE_REGISTER_REQUEST: 'message_register_request',
    MESSAGE_REGISTER_SUCCESS: 'message_register_success',

    MESSAGE_DELETE_REQUEST: 'message_delete_request',
    MESSAGE_DELETE_SUCCESS: 'message_delete_success',

    PARTICULAR_MESSAGE_REQUEST: 'particular_message_request',
    PARTICULAR_MESSAGE_SUCCESS: 'particular_message_success',

    MESSAGE_PUBLISH_UNPUBLISH_REQUEST: 'message_publish_unpublish_request',
    MESSAGE_PUBLISH_UNPUBLISH_SUCCESS: 'message_publish_unpublish_success',
}
export default MessageActionTypes;