import React from 'react';
import { Route } from 'react-router-dom';
import Index from './view/index';
import CropGeneral from './view/cropGeneral/cropGeneral';
import General from './view/general/general';
import Variety from './view/variety/variety';
import Characteristics from './view/characteristics/characteristics';
import Indicator from './view/indicator/indicator';
import FertilizerGeneral from  './view/fertilizerGeneral/fertilizerGeneral';
import Activity from './view/activity/activity';
import PestDiseases from '../pestDiseases/view/index';
import Media from '../media/view/index';
import Calendar from './view/cropCalendars/index';

const Crop = props => {
    return (
       <>
            <Route
                exact
                path={`${props.match.path}`}
                component={Index}
            />

            <Route 
                path={`${props.match.path}/detail`}
                component={CropGeneral}
            />

            <Route 
                path={`${props.match.path}/general`}  
                component={General}
            />

            <Route 
                path={`${props.match.path}/variety`}
                component={Variety}
            />

            <Route 
                path={`${props.match.path}/characteristics`}
                component={Characteristics}
            />

            <Route 
                path={`${props.match.path}/indicator`}
                component={Indicator}
            />

            <Route 
                path={`${props.match.path}/fertilizer`}
                component={FertilizerGeneral}
            />
            
            <Route 
                path={`${props.match.path}/activity_media`}
                component={Activity}
            />

            <Route 
                path={`${props.match.path}/pest_diseases`}
                component={PestDiseases}
            />

            <Route 
                path={`${props.match.path}/media`}
                component={Media}
            />
            
            <Route 
                path={`${props.match.path}/crop_calendars`}
                component={Calendar}
            />
       </>
    )
}

export default Crop;