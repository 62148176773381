import UsageActionTypes from './actionTypes';

const INITIAL_STATE = {
   usage_list_loading: false,
   usage_register_loading: false,
   usage_delete_loading: false,
   usage_list: [],
   category_list: [],
   version_list: [],
}

export default function(state = INITIAL_STATE, action){
    switch(action.type){
        case UsageActionTypes.USAGE_LIST_REQUEST:
            return {
                ...state,
                usage_list_loading: true
            }
        case UsageActionTypes.USAGE_LIST_SUCCESS:
            return {
                ...state,
                usage_list_loading: false,
                usage_list: action.usage_list,
            }

        case UsageActionTypes.USAGE_REGISTER_REQUEST:
            return {
                ...state,
                usage_register_loading: true
            }
        case UsageActionTypes.USAGE_REGISTER_SUCCESS:
            return {
                ...state,
                usage_register_loading: false
            }

        case UsageActionTypes.USAGE_CATEGORY_LIST_SUCCESS:
            return {
                ...state,
                category_list: action.category_list
            }

        case UsageActionTypes.USAGE_DELETE_REQUEST:
            return {
                ...state,
                usage_delete_loading: true
            }

        case UsageActionTypes.USAGE_DELETE_SUCCESS:
            return {
                ...state,
                usage_delete_loading: false
            }
        case UsageActionTypes.VERSION_LIST_SUCCESS:
            return {
                ...state,
                version_list: action.version_list
            }
        default: 
            return state;
    }
}