const GrowAreaActionTypes={
    GROW_AREA_LIST_REQUEST: 'grow_area_list_request',
    GROW_AREA_LIST_SUCCESS: 'grow_area_list_success',
    GROW_AREA_LIST_FAILURE: 'grow_area_list_failure',
    GROW_AREA_SELECT_REQUEST: 'grow_area_select_request',
    REMOVE_SELECTED_GROW_AREA: 'remove_selected_grow_area',

    REGISTER_GROW_AREA_REQUEST: 'register_grow_area_request',
    REGISTER_GROW_AREA_SUCCESS: 'register_grow_area_success',
    REGISTER_GROW_AREA_FAILURE: 'register_grow_area_failure',

    DELETE_GROW_AREA_REQUEST: 'delete_grow_area_request',
    DELETE_GROW_AREA_SUCCESS: 'delete_grow_area_success',
}
export default GrowAreaActionTypes;