import GrowAreaActionTypes from './actionTypes';

export function growAreaListRequest(data){
    return {
        type: GrowAreaActionTypes.GROW_AREA_LIST_REQUEST,
        data
    }
}

export function selectGrowArea(selected_grow_area){
    return {
        type: GrowAreaActionTypes.GROW_AREA_SELECT_REQUEST,
        selected_grow_area
    }
}

export function removeSelectedGrowArea(){
    return {
        type: GrowAreaActionTypes.REMOVE_SELECTED_GROW_AREA
    }
}

export function registerGrowAreaRequest(data){
    return {
        type: GrowAreaActionTypes.REGISTER_GROW_AREA_REQUEST,
        data
    }
}

export function deleteGrowAreaRequest(grow_area_id){
    return {
        type: GrowAreaActionTypes.DELETE_GROW_AREA_REQUEST,
        grow_area_id
    }
}