import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CropImages from '../images/images';
import { Link } from 'react-router-dom';
import history from '../config/history';
import { clearCropDetail, cropCopyRequest } from '../components/crop/action';
import Button from './button';
import Modal from 'react-modal';

const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
    },
};

Modal.setAppElement('#root');

const SelectedCropList = (props) => {
    const [modalIsOpen,setIsOpen] = React.useState(false);
    const getIndex = list => {
        let data = list.findIndex((tmp)=>{return tmp.locale === "en_EN"});
        return data;
    }

    const getCropName = (crop_id) => {
        let data = props.base_org_crop_list.filter((tmp)=>{return tmp.id === crop_id});
        let index = getIndex(data[0].crop_text);
        if(data.length > 0){
            if(index !== -1)
                return data[0].crop_text[index].displayName;
            else
                if(data[0].crop_text.length > 0)
                    return data[0].crop_text[0].displayName;
                else    
                    return "";
        }
    }

    const onSaveButtonClick = () => {
        //let data = {"crop_list": props.selected_crop_list, "organisation": props.organisation};

        let base_crop_list = [];
        let crop_to_be_added = [];
        let crop_to_be_removed = [];
        //let donot_check_crop_list = [];

        if(props.cropList.length > 0){
            props.cropList.map((tmp)=>{base_crop_list.push(tmp.id)})
            crop_to_be_removed = base_crop_list.filter(x=>!props.selected_crop_list.includes(x));
            crop_to_be_added = props.selected_crop_list.filter(x=>!base_crop_list.includes(x));
        }
        else{
            crop_to_be_added = props.selected_crop_list;
        }

        let data = {
            "org_id": props.organisation_id,
            "country": "BI",
            "add_crop_id": crop_to_be_added,
            "delete_crop_id": crop_to_be_removed,
            "org_name": props.organisation
        }

        props.actions.cropCopyRequest(data);
        closeModal();
    }

    const openModal = () => {
        setIsOpen(true);
    }
    const closeModal = () => {
        setIsOpen(false);
    }

    return (
        <div className="selectedCropMainContainer">
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <img src={require(`../images/cross.png`)} alt="cross" style={{height: '10px', paddingTop: '10px', cursor: 'pointer', float: 'right', width: '15px', height: 'px'}} onClick={(e)=>{props.closeModal(e)}} />
            </div>

            <Link 
                className="cropBackContainer"
                to={window.location.pathname.includes("/crop/") === true ? 
                    window.location.pathname.includes("/crop/crop_calendars/edit") === true || window.location.pathname.includes("/crop/crop_calendars/media") === true  ?
                        "/crop/crop_calendars"
                    :
                        "/crop" 
                : 
                    "/home"}
                onClick={()=>{
                    window.location.pathname.includes("/crop/crop_calendars/") === true ?
                        history.goBack()
                    :
                        props.actions.clearCropDetail()
                }}
            >
                <img 
                    src={require("../images/back.png")}
                    alt="back"
                />
                
            </Link>

            {
                //props.selected_crop_list.length > 0 &&
                    <div style={{display: 'flex', flexDirection:'row', justifyContent: 'space-between'}}>
                        <div style={{flex:8}}>Selected Crops</div>
                        <div style={{flex:2}}>
                            <Button 
                                image={true} 
                                name="Select or unselect Crop" 
                                width={"100%"} 
                                onSaveButtonClick={openModal}
                            />
                        </div>
                    </div>
            }

            <div className="selectedCropContainer" >
            {
                props.selected_crop_list.length > 0 &&
                    props.selected_crop_list.map((crop_id, key)=>{
                        return (
                            <div key={key} className="selectedImageContainer">
                                {
                                    CropImages[crop_id] !== undefined ?
                                        <img 
                                            src={CropImages[crop_id]} 
                                            alt="crops"
                                            className="cropImage"
                                        />
                                    :
                                        <div className="emptyImage cropImage"></div>
                                }
                                <div className="cropName">{
                                    getCropName(crop_id)
                                }</div>
                            </div>
                        )
                    })
            }
            {
                //props.user_role_type !== "Viewer" &&
                    <Modal
                        isOpen={ modalIsOpen }
                        onRequestClose={(e)=>{
                            e.stopPropagation();
                            e.preventDefault();
                        }}
                        style={customStyles}
                    >
                        <h4>Are you sure you want to select/unselect this crop ?</h4>
                        <div className="modalBox">
                            <div className="leftModal">
                                <div 
                                    className="modalButton"
                                    onClick={onSaveButtonClick}
                                >
                                    Yes
                                </div>
                            </div>
        
                            <div className="rightModal">
                                <div 
                                    className="modalButton" 
                                    onClick={closeModal}
                                >
                                    No
                                </div>
                            </div>
                        </div>
                    </Modal>
            }
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
       cropList: state.crop.crop_list,
       //selected_crop_list: state.crop.selected_crop_list,
       selected_crop_list_counter: state.crop.selected_crop_list_counter,
       organisation: state.auth.organization,
       organisation_id: state.auth.uid,
       base_org_crop_list: state.crop.base_org_crop_list,
       user_role_type: state.auth.user_role_type,
    };
};

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                clearCropDetail,
                cropCopyRequest
            },
            dispatch
        )
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SelectedCropList);