import UsageActionTypes from './actionTypes';

import { fork, take, call, put, select } from "redux-saga/effects";

import { UsageService } from "./service";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const getOrganisation = state => state.auth;  

export function* usageListRequest(){
    while(true){
        const { data } = yield take(UsageActionTypes.USAGE_LIST_REQUEST);

        var usage_list = yield call(UsageService.getUsageList, data);
        
        if(Object.values(usage_list).length > 0){
            if("error" in usage_list  === true){
                yield put({
                    type: UsageActionTypes.USAGE_LIST_SUCCESS,
                    usage_list: []
                });
            }
            else{
                usage_list = usage_list.sort((a,b)=>{return (a.category_id - b.category_id || a.sorting - b.sorting)});
            }

            yield put({
                type: UsageActionTypes.USAGE_LIST_SUCCESS,
                usage_list: usage_list
            });
        }
        else{
            yield put({
                type: UsageActionTypes.USAGE_LIST_SUCCESS,
                usage_list: usage_list
            });
        }
    } 
}

export function* usageRegisterRequest(){
    while(true){
        const { params } = yield take(UsageActionTypes.USAGE_REGISTER_REQUEST);

        var usage_register = yield call(UsageService.registerUsage, params);
        
        if(typeof usage_register === 'object'){
            toast.error(usage_register.error.message, {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });

            yield put({
                type: UsageActionTypes.USAGE_REGISTER_SUCCESS
            })
        }
        else if(usage_register === "usages data added"){
            let org = yield select(getOrganisation);

            var usage_list = yield call(UsageService.getUsageList, {"org_id": org.uid});
                
            if(Object.values(usage_list).length > 0){
                if("error" in usage_list  === true){
                    yield put({
                        type: UsageActionTypes.USAGE_LIST_SUCCESS,
                        usage_list: []
                    });
                }
                else{
                    usage_list = usage_list.sort((a,b)=>{return (a.category_id - b.category_id || a.sorting - b.sorting)});
                }
    
                yield put({
                    type: UsageActionTypes.USAGE_LIST_SUCCESS,
                    usage_list: usage_list
                });
            }
            else{
                yield put({
                    type: UsageActionTypes.USAGE_LIST_SUCCESS,
                    usage_list: usage_list
                });
            }

            toast.success("Usage has been added successfully.", {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });

            yield put({
                type: UsageActionTypes.USAGE_REGISTER_SUCCESS
            })
        }
    } 
}

export function* categoryListRequest(){
    while(true){
        yield take(UsageActionTypes.USAGE_CATEGORY_LIST_REQUEST);

        var category_list = yield call(UsageService.getCategoryList);
        
        if(Object.values(category_list).length > 0){
            yield put({
                type: UsageActionTypes.USAGE_CATEGORY_LIST_SUCCESS,
                category_list: category_list
            })
        }
    }
}

export function* usageDeleteRequest(){
    while(true){
        const { data } = yield take(UsageActionTypes.USAGE_DELETE_REQUEST);

        var deleted_data = yield call(UsageService.deleteUsageRequest, data);
        
        if(typeof deleted_data === "object"){
            toast.error(deleted_data.error.message, {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });

            yield put({
                type: UsageActionTypes.USAGE_DELETE_SUCCESS
            })
        }
        else{
            if(deleted_data === "Selected rows has been deleted."){
                var usage_list = yield call(UsageService.getUsageList, {"org_id": data.org_id});
                
                if(Object.values(usage_list).length > 0){
                    if("error" in usage_list  === true){
                        yield put({
                            type: UsageActionTypes.USAGE_LIST_SUCCESS,
                            usage_list: []
                        });
                    }
                    else{
                        usage_list = usage_list.sort((a,b)=>{return (a.category_id - b.category_id || a.sorting - b.sorting)});
                    }
        
                    yield put({
                        type: UsageActionTypes.USAGE_LIST_SUCCESS,
                        usage_list: usage_list
                    });
                }
                else{
                    yield put({
                        type: UsageActionTypes.USAGE_LIST_SUCCESS,
                        usage_list: usage_list
                    });
                }

                toast.success("Selected usage has been deleted successfully.", {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });
    
                yield put({
                    type: UsageActionTypes.USAGE_DELETE_SUCCESS
                })
            }
        }
    }
}

export function* versionListRequest(){
    while(true){
        yield take(UsageActionTypes.VERSION_LIST_REQUEST);

        var version_list = yield call(UsageService.getVersions);

        yield put({
            type: UsageActionTypes.VERSION_LIST_SUCCESS,
            version_list: version_list
        })
    }
}

export default function* UsageSaga(){
  yield fork(usageListRequest);
  yield fork(usageRegisterRequest);
  yield fork(categoryListRequest);
  yield fork(usageDeleteRequest);
  yield fork(versionListRequest);
}
