import React from 'react';
import { Route } from 'react-router-dom';
import Index from './view/index';

const Media = props => {
    return (
       <>
            <Route
                exact
                path={`${props.match.path}`}
                component={Index}
            />
       </>
    )
}

export default Media;