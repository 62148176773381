import ScaleValueActionTypes from './actionTypes';

import { fork, take, call, put, delay } from "redux-saga/effects";

import { ScaleValueService } from "./service";

import history from '../../config/history';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function* scaleTypeRequest() {
    while(true){
        const { org } = yield take(ScaleValueActionTypes.SCALE_TYPE_LIST_REQUEST);
         
        var scale_type = yield call(ScaleValueService.scaleTypeRequest, org);
       
        if(scale_type){
            yield put({
                type: ScaleValueActionTypes.SCALE_TYPE_LIST_SUCCESS,
                scale_type_list: scale_type
            })
        }
        else{
            yield put({
                type: ScaleValueActionTypes.SCALE_TYPE_LIST_FAILURE,
            })
        }
    }
}

export function* scaleValueRequest() {
    while(true){
        const { data } = yield take(ScaleValueActionTypes.SCALE_VALUE_LIST_REQUEST);
        let data1 = {};
        
        data1.organisation = JSON.parse(localStorage.getItem('user')).organization;

        var scale_value_list = yield call(ScaleValueService.scaleValueRequest, data1);

        if(scale_value_list){
            var temp_scale_value = ""
            scale_value_list.map((tempData)=>{
                if(tempData.type === temp_scale_value){
                    tempData.display = false
                }
                else{
                    tempData.display = true;
                }
                temp_scale_value = tempData.type;
            });

            yield put({
                type: ScaleValueActionTypes.SCALE_VALUE_LIST_SUCCESS,
                scale_value_list: scale_value_list
            })
        }
        else{
            yield put({
                type: ScaleValueActionTypes.SCALE_VALUE_LIST_FAILURE,
            })
        }
    }
}

export function* scaleValueRegisterRequest() {
    while(true){
        const { data } = yield take(ScaleValueActionTypes.SCALE_VALUE_ADD_REQUEST);
         
        var scale_value = yield call(ScaleValueService.registerScaleValue, data);

        if(scale_value.includes("Scale values are added to type=") === true){
            toast.success("Data has been added/updated successfully", {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });

            yield put({
                type: ScaleValueActionTypes.SCALE_VALUE_ADD_SUCCESS
            })
            let data1 = {};
            data1.organisation = JSON.parse(localStorage.getItem('user')).organization;

            var scale_value_list = yield call(ScaleValueService.scaleValueRequest, data1);
       
            if(scale_value_list){
                yield put({
                    type: ScaleValueActionTypes.SCALE_VALUE_LIST_SUCCESS,
                    scale_value_list: scale_value_list
                })
            }
            else{
                yield put({
                    type: ScaleValueActionTypes.SCALE_VALUE_LIST_FAILURE,
                })
            }

            yield delay(1000);

            history.push(`/home`);
            history.push(`/scale_value`);
        }
        else{
            yield put({
                type: ScaleValueActionTypes.SCALE_VALUE_LIST_FAILURE,
            })
        }
    }
}

export function* deleteScaleValue(){
    while(true){
        const { data } = yield take(ScaleValueActionTypes.DELETE_SCALE_VALUE_REQUEST);

        var scale_value = yield call(ScaleValueService.deleteScaleValues, data);
        
        if(scale_value === "Selected rows has been deleted."){

            toast.success("Data has been deleted successfully", {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });

            yield put({
                type: ScaleValueActionTypes.DELETE_SCALE_VALUE_SUCCES
            })

            var scale_value_list = yield call(ScaleValueService.scaleValueRequest);
       
            if(scale_value_list){
                yield put({
                    type: ScaleValueActionTypes.SCALE_VALUE_LIST_SUCCESS,
                    scale_value_list: scale_value_list
                })
            }
            else{
                yield put({
                    type: ScaleValueActionTypes.SCALE_VALUE_LIST_FAILURE,
                })
            }

            yield delay(2000);

            history.push(`/home`);
            history.push(`/scale_value`);
        }
        else{
            yield put({
                type: ScaleValueActionTypes.DELETE_SCALE_VALUE_FAILURE,
            })
        }
    }
}

export function* registerScaleTypes(){
    while(true){
        const { data } = yield take(ScaleValueActionTypes.REGISTER_SCALE_TYPE_REQUEST);

        var scale_type = yield call(ScaleValueService.registerScaleTypes, data);
        
        if(typeof(scale_type) !== "object"){
            if(scale_type.includes("Scale:") === true){
                toast.success("Data has been added/updated successfully", {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });

                yield put({
                    type: ScaleValueActionTypes.REGISTER_SCALE_TYPE_SUCCESS
                })

                var scale_type = yield call(ScaleValueService.scaleTypeRequest);
        
                if(scale_type){
                    yield put({
                        type: ScaleValueActionTypes.SCALE_TYPE_LIST_SUCCESS,
                        scale_type_list: scale_type
                    })
                }
                else{
                    yield put({
                        type: ScaleValueActionTypes.SCALE_TYPE_LIST_FAILURE,
                    })
                }

                yield delay(2000);

                history.push(`/home`);
                history.push(`/scale_value`);
            }
        }
        else{
            toast.error(scale_type.error.message, {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });
            yield put({
                type: ScaleValueActionTypes.REGISTER_SCALE_TYPE_FAILURE
            })
        }
    }
}

export function* deleteScaleType() {
    while(true){
        const { data } = yield take(ScaleValueActionTypes.DELETE_SCALE_TYPE_REUEST);

        let scale_type_data = yield call(ScaleValueService.deleteScaleType, data);
        if(scale_type_data === "Selected rows has been deleted."){
            toast.success("Data has been deleted successfully", {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });

            yield put({
                type: ScaleValueActionTypes.DELETE_SCALE_TYPE_SUCCESS
            })

            var scale_type = yield call(ScaleValueService.scaleTypeRequest);
       
            if(scale_type){
                yield put({
                    type: ScaleValueActionTypes.SCALE_TYPE_LIST_SUCCESS,
                    scale_type_list: scale_type
                })
            }
            else{
                yield put({
                    type: ScaleValueActionTypes.SCALE_TYPE_LIST_FAILURE,
                })
            }

            yield delay(2000);

            history.push(`/home`);
            history.push(`/scale_value`);
        }
        else{
            yield put({
                type: ScaleValueActionTypes.DELETE_SCALE_TYPE_FAILURE
            })
        }
    }
}

export default function* ScaleValueSaga(){
    yield fork(scaleTypeRequest);
    yield fork(scaleValueRequest);
    yield fork(scaleValueRegisterRequest);
    yield fork(deleteScaleValue);
    yield fork(registerScaleTypes);
    yield fork(deleteScaleType);
}
