export default function errorParse(error) {
    if (error.response) {
        return error.response.data;
    }
    else {
        return {
            status: "error", error: { code: 404, message: "please check api point or network" }
        }
    }
}
