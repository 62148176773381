import ActivityMediaActionTypes from './actionTypes';

export function activityList(crop_id) {
    return {
        type: ActivityMediaActionTypes.CROP_ACTIVITY_MEDIA_LIST_REQUEST,
        crop_id
    }
}

export function specificCropActivityListRequest(data, all_media_list){
    return {
        type: ActivityMediaActionTypes.SPECIFIC_CROP_ACTIVITY_LIST_REQUEST,
        data,
        all_media_list
    }
}

export function genericCropActivityListRequest(data, all_media_list){
    return {
        type: ActivityMediaActionTypes.GENERIC_CROP_ACTIVITY_LIST_REQUEST,
        data,
        all_media_list
    }
}

export function specificCropActivityListRequest1(data){
    return {
        type: ActivityMediaActionTypes.SPECIFIC_CROP_ACTIVITY_LIST_REQUEST1,
        data
    }
}

export function activityMediaRegister(data){
    return {
        type: ActivityMediaActionTypes.ACTIVITY_MEDIA_REGISTER_REQUEST,
        data
    }
}

export function removeSpecificActivity(){
    return {
        type: ActivityMediaActionTypes.REMOVE_SPECIFIC_ACTIVITY
    }
}

export function deleteActivityMedia(data){
    return {
        type: ActivityMediaActionTypes.DELETE_ACTIVITY_MEDIA_REQUEST,
        data
    }
}

export function clearSelectedActivityMedia(){
    return {
        type: ActivityMediaActionTypes.CLEAR_SELECTED_ACTIVITY_MEDIA
    }
}

export function cropSpecificActivityRequest(){
    return {
        type: ActivityMediaActionTypes.CROP_SPECIFIC_ACTIVITY_REQUEST
    }
}

export function genericActivityRequest(){

}

export function allMediaListRequest(crop_id, organisation){
    return {
        type: ActivityMediaActionTypes.ALL_MEDIA_LIST_REQUEST,
        crop_id,
        organisation
    }
}

export function allGenericListRequest(crop_id){
    return {
        type: ActivityMediaActionTypes.ALL_GENERIC_MEDIA_LIST_REQUEST,
        crop_id
    }
}

export function selectedGenericActivity(data){
    return {
        type: ActivityMediaActionTypes.SELECTED_GENERIC_ACTIVITY,
        data
    }
}

export function removeSelectedGenericActivity(){
    return {
        type: ActivityMediaActionTypes.REMOVE_SELECTED_GENERIC_ACTIVITY
    }
}
