const ActivityActionTypes = {
    ACTIVITY_REGISTER_REQUEST: 'activity.register.request',
    ACTIVITY_REGISTER_SUCCESS: 'activity.register.success',

    CATEGORY_LIST_REQUEST: 'category_list_request',
    CATEGORY_LIST_SUCCESS: 'category_list_success',

    ACTIVITY_LIST_REQUEST: 'activity_list_request',
    ACTIVITY_LIST_SUCCESS: 'activity_list_success',

    DELETE_ACTIVITY_REQUEST: 'delete_activity_request',
    DELETE_ACTIVITY_SUCCESS: 'delete_activity_success',

    ACTIVITY_SORTING_LIST_REQUEST: 'activity_sorting_list_request',
    ACTIVITY_SORTING_LIST_SUCCESS: 'activity_sorting_list_success',

    REMOVE_ACTIVITIES: 'remove_activities',

    UPDATE_ACTIVITY_STATUS_REQUEST: 'update_activity_status_request',
    UPDATE_ACTIVITY_STATUS_SUCCESS: 'update_activity_status_success',
}
export default ActivityActionTypes;