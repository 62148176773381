import Api from '../../config/api';
import errorParse from '../lib/error';   

function getOauthToken(payload) {
  const res = Api.post("/oauth/token", payload)   
    .then(response => {
      let data = response;
      return data;
    })
    .catch(error => {
      return errorParse(error);
    });
  return res;
}

function loginUser( params ) {
    const res = Api.post("/login", params)   
    .then(response => {
      let data = response;
      return data;
    })
    .catch(error => {
      return errorParse(error);
    });
  return res;
}

function getRole( params ) {
  const res = Api.get("/getRole", {params})
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return errorParse(error);
    });
  return res;
}

function getLocale( params ) {
  const res = Api.get("/getlocale", {params})
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return errorParse(error);
    });
  return res;
}

function getDetailAll( params ) {
  const res = Api.get("/user/detailAll", {params})
    .then(response => {
      let data = response.data;
      return data;
    })
    .catch(error => {
      return errorParse(error);
    });
  return res;
}

export const AuthService = { 
  loginUser,
  getRole,
  getLocale,
  getDetailAll,
  getOauthToken
}