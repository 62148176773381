const ScaleValueActionTypes={
    SCALE_TYPE_LIST_REQUEST: 'scale_type_list_request',
    SCALE_TYPE_LIST_SUCCESS: 'scale_type_list_success',
    SCALE_TYPE_LIST_FAILURE: 'scale_type_list_failure',

    SCALE_VALUE_LIST_REQUEST: 'scale_value_list_request',
    SCALE_VALUE_LIST_SUCCESS: 'scale_value_list_success',
    SCALE_VALUE_LIST_FAILURE: 'scale_value_list_failure',

    SCALE_VALUE_ADD_REQUEST: 'scale_value_add_request',
    SCALE_VALUE_ADD_SUCCESS: 'scale_value_add_success',
    SCALE_VALUE_ADD_FAILURE: 'scale_value_add_failure',

    DELETE_SCALE_VALUE_REQUEST: 'delete_request_value_request',
    DELETE_SCALE_VALUE_SUCCES: 'delete_scale_value_success',
    DELETE_SCALE_VALUE_FAILURE: 'delete_scale_value_failure',

    REGISTER_SCALE_TYPE_REQUEST: 'register_scale_type_request',
    REGISTER_SCALE_TYPE_SUCCESS: 'register_scale_type_success',
    REGISTER_SCALE_TYPE_FAILURE: 'register_scale_type_failure',

    DELETE_SCALE_TYPE_REUEST: 'delete_scale_type_request',
    DELETE_SCALE_TYPE_SUCCESS: 'delete_scale_type_success',
    DELETE_SCALE_TYPE_FAILURE: 'delete_scale_type_failure',
}
export default ScaleValueActionTypes;