import React from 'react';
import { Route } from 'react-router-dom';
import AssignRole from './view/assignRole';
import AddRole from './view/addRole';

const Role = props => {
    return (
       <>
            <Route 
                exact
                path={`${props.match.path}/add_role`}
                component={AddRole}
            />

            <Route 
                path={`${props.match.path}/assign_role`}
                component={AssignRole}
            />
       </>
    )
}

export default Role;