const fr ={
    label: 'French',
    value: 'fr',
    translation: {
        "UMVA ID": "UMVA ID",
        "Password": "Mot de passe",
        "Login": "Ouverture de session",
        "Logout": "Déconnexion",
        "UMVA ID is required": "Identification UMVA obligatoire",
        "User not exist": "l'utilisateur n'existe pas",
        "Password is invalid": "Password is not correct",
        "App title": "Les activateurs clés et le personnel d'Auxfin ont un compte/mot de passe qui permettent l'accès à l'Agricoach.",
        "You must enter UMVA ID": "UMVA ID est nécessaire.",
        "You must enter a password": "vous devez entrer un mot de passe.",
        "Forgot your password?":"Avez-vous oublié votre Mot de passe?",
        "User does not exists": "Utilisateur inexistant",
        "Password is not correct": "Mot de passe incorrecte.",
        "No Internet Connection": "Pas de connexion internet",
        "Need help": "Besoin d'aide?",
        "English": "Anglais",
        "French": "Fran\u00e7ais",
        "Kirundi": "Kirundi",
        "Crops": "Culture",
        "Pest and diseases": "Ravageurs et maladies",
        "Seasons": "Saisons",
        "Bean goblets": " Gobelet mesurant le haricot",
    }
}
export default fr;