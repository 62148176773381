import Api from "../../../../config/api";
import errorParse from '../../../../components/lib/error';

function activityList(params) {
    const res = Api.get('/cropActivityList',{ params }).then((response)=>{
        let data = response.data;
        return data;
    }).catch((error)=>{
        return errorParse(error);
    });
    return res;
}

function specificActivityMediaList(params) {
    const res = Api.get('/activityMediaList',{ params }).then((response)=>{
        let data = response.data;
        return data;
    }).catch((error)=>{
        return errorParse(error);
    });
    return res;
}

function genericActivityMediaList(params) {
    const res = Api.get('/genericActivityMedia',{ params }).then((response)=>{
        let data = response.data;
        return data;
    }).catch((error)=>{
        return errorParse(error);
    });
    return res;
}

function registerActivityMedia(params) {
    const res = Api.post('/activityMedia',params).then((response)=>{
        let data = response.data;
        return data;
    }).catch((error)=>{
        return errorParse(error);
    });
    return res;
}

function deleteActivityData(params){
    const res = Api.get('/deleteMediaActivity',{ params }).then((response)=>{
        let data = response.data;
        return data;
    }).catch((error)=>{
        return errorParse(error);
    });
    return res;
}

function genericActivityList(params){
    const res = Api.get('/genericActivityList',{params}).then((response)=>{
        let data = response.data;
        return data;
    }).catch((error)=>{
        return errorParse(error);
    });
    return res;
}

function cropSpecificActivityRequest(params){
    const res = Api.get('/getActivitiesList', {params}).then((response)=>{
        let data = response.data;
        return data;
    }).catch((error)=>{
        return errorParse(error);
    });
    return res;
}

function allMediaList(params){
    const res = Api.get('/allMediaList',{params}).then((response)=>{
        let data = response.data;
        return data;
    }).catch((error)=>{
        return errorParse(error);
    });
    return res;
}

function allGenericMediaList(params){
    const res = Api.get('/allGenericMediaList',{params}).then((response)=>{
        let data = response.data;
        return data;
    }).catch((error)=>{
        return errorParse(error);
    });
    return res;
}

export const ActivityMediaService = { 
    activityList,
    specificActivityMediaList,
    genericActivityMediaList,
    registerActivityMedia,
    deleteActivityData,
    genericActivityList,
    cropSpecificActivityRequest,
    allMediaList,
    allGenericMediaList,
}