import React from 'react';
import './styles.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './index.scss';

const CustomButton = (props) => {
    return (
        <div 
            className="customButton"
            onClick={()=>{props.onSaveButtonClick()}}
            style={"width" in props === true ? {width: '100%'} : props.styles}
        >
            {
                "image" in props === false &&
                    <img src={require('../images/save_white.png')} alt="save" className="buttonImageContainer" />
            }

            <div className="buttonTextContainer">
                {props.name} 
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
       lang: state.lang.language
    };
};

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                
            },
            dispatch
        )
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomButton);