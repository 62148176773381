const CropCalendarActionTypes = {
   CROP_CALENDAR_ACTIVITY_REQUEST: 'crop_calendar_activity_request',
   CROP_CALENDAR_ACTIVITY_SUCCESS: 'crop_calendar_activity_success',

   SELECTED_CROP_CALENDAR_MEDIA: 'selectd_crop_calendar_media',
   CROP_CALENDAR_ACTIVITY_EDIT: 'crop_calendar_activiyt_edit',

   REGISTER_CROP_CALENDAR_ACTIVITY_REQUEST: 'register_crop_calendar_activity_request',
   REGISTER_CROP_CALENDAR_ACTIVITY_SUCCESS: 'register_crop_calendar_activity_success',

   DELETE_ACTIVITY_MEDIA_REQUEST: 'delete_activity_media_request',
   DELETE_ACTIVITY_MEDIA_SUCCESS: 'delete_activity_media_success',

   GENERIC_ACTIVITIES_REQUEST: 'generic_activities_request',
   GENERIC_ACTIVITIES_SUCCESS: 'generic_activities_success',

   ACTIVITY_CALENDAR_LIST_REQUEST: 'activity_calendar_list_request',
   ACTIVITY_CALENDAR_LIST_SUCCESS: 'activity_calendar_list_success',
}
export default CropCalendarActionTypes;