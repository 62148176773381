import MessageActionTypes from './actionTypes';

import { fork, take, call, put, select } from "redux-saga/effects";

import { MessageService } from "./service";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const getOrganisation = state => state.auth;  

export function* messageListRequest(){
    while(true){
        const { data } = yield take(MessageActionTypes.MESSAGE_LIST_REQUEST);

        var message_list = yield call(MessageService.getMessageList, data);
        
        if(Object.values(message_list).length > 0){
            if("error" in message_list  === true){
                yield put({
                    type: MessageActionTypes.MESSAGE_LIST_SUCCESS,
                    message_list: []
                });
            }
            else{
                yield put({
                    type: MessageActionTypes.MESSAGE_LIST_SUCCESS,
                    message_list: message_list
                });
            }
        }
        else{
            yield put({
                type: MessageActionTypes.MESSAGE_LIST_SUCCESS,
                message_list: []
            });
        }
    } 
}

export function* messageRegisterRequest(){
    while(true){
        const { params } = yield take(MessageActionTypes.MESSAGE_REGISTER_REQUEST);

        var message_register = yield call(MessageService.registerMessage, params);
        
        if(typeof message_register === 'object'){
            toast.error(message_register.error.message, {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });

            yield put({
                type: MessageActionTypes.MESSAGE_REGISTER_SUCCESS
            })
        }
        else if(message_register === "The message is saved."){
            let org = yield select(getOrganisation);

            var message_list = yield call(MessageService.getMessageList, {"org_id": org.uid, "version": ""});
                
            if(Object.values(message_list).length > 0){
                if("error" in message_list  === true){
                    yield put({
                        type: MessageActionTypes.MESSAGE_LIST_SUCCESS,
                        message_list: []
                    });
                }
                else{
                    yield put({
                        type: MessageActionTypes.MESSAGE_LIST_SUCCESS,
                        message_list: message_list
                    });
                }
            }
            else{
                yield put({
                    type: MessageActionTypes.MESSAGE_LIST_SUCCESS,
                    message_list: []
                });
            }

            toast.success("Messages has been added successfully.", {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });

            yield put({
                type: MessageActionTypes.MESSAGE_REGISTER_SUCCESS
            })
        }
    } 
}

export function* messageDeleteRequest(){
    while(true){
        const { data } = yield take(MessageActionTypes.MESSAGE_DELETE_REQUEST);

        var deleted_data = yield call(MessageService.deleteMessageRequest, data);

        if(typeof deleted_data === "object"){
            toast.error(deleted_data.error.message, {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });

            yield put({
                type: MessageActionTypes.MESSAGE_DELETE_SUCCESS
            })
        }
        else{
            if(deleted_data === "Selected rows has been deleted."){
                var message_list = yield call(MessageService.getMessageList, {"org_id": data.org_id, "version": ""});

                if(Object.values(message_list).length > 0){
                    if("error" in message_list  === true){
                        yield put({
                            type: MessageActionTypes.MESSAGE_LIST_SUCCESS,
                            message_list: []
                        });
                    }
                    else{
                        yield put({
                            type: MessageActionTypes.MESSAGE_LIST_SUCCESS,
                            message_list: message_list
                        });
                    }
                }
                else{
                    yield put({
                        type: MessageActionTypes.MESSAGE_LIST_SUCCESS,
                        messsage_list: []
                    });
                }

                toast.success("Selected message has been deleted successfully.", {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });
    
                yield put({
                    type: MessageActionTypes.MESSAGE_DELETE_SUCCESS
                })
            }
        }
    }
}

export function* messagePublishUnpublishRequest(){
    while(true){
        const { data } = yield take(MessageActionTypes.MESSAGE_PUBLISH_UNPUBLISH_REQUEST);

        let publish_unpublish = yield call(MessageService.publishUnpublishRequest, data);

        if(publish_unpublish === "The message is unpublished." || publish_unpublish === "The message is published."){
            var message_list = yield call(MessageService.getMessageList, {"org_id": data.org_id, "version": ""});

                if(Object.values(message_list).length > 0){
                    if("error" in message_list  === true){
                        yield put({
                            type: MessageActionTypes.MESSAGE_LIST_SUCCESS,
                            message_list: []
                        });
                    }
                    else{
                        yield put({
                            type: MessageActionTypes.MESSAGE_LIST_SUCCESS,
                            message_list: message_list
                        });
                    }
                }
                else{
                    yield put({
                        type: MessageActionTypes.MESSAGE_LIST_SUCCESS,
                        messsage_list: []
                    });
                }

                let message = data.is_published === true ? "Selected message has been published successfully." : "Selected message has been unpublished successfully.";
                toast.success(message, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });
    
                yield put({
                    type: MessageActionTypes.MESSAGE_PUBLISH_UNPUBLISH_SUCCESS
                })
        }
    }
}

export default function* MessageSaga(){
  yield fork(messageListRequest);
  yield fork(messageRegisterRequest);
  yield fork(messageDeleteRequest);
  yield fork(messagePublishUnpublishRequest);;
}
