import Api from "../../config/api";
import errorParse from '../lib/error';

function growAreaRequest(params) {
    const res = Api.get('/growAreaList', {params}).then((response)=>{
        let data = response.data;
        return data;
    }).catch((error)=>{
        return errorParse(error);
    });
    return res;
}

function registerGrowArea(params) {
    const res = Api.post('/addGrowAreas',params).then((response)=>{
        let data = response.data;
        return data;
    }).catch((error)=>{
        return errorParse(error);
    });
    return res;
}

function deleteGrowAreaValues(params) {
    const res = Api.get('/deleteGrowAreaValues',{ params }).then((response)=>{
        let data = response.data;
        return data;
    }).catch((error)=>{
        return errorParse(error);
    });
    return res;
}

export const GrowAreaService = { 
    growAreaRequest,
    registerGrowArea,
    deleteGrowAreaValues
}