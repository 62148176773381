import RoleActionTypes from './actionTypes';

import { fork, take, call, put, select, delay } from "redux-saga/effects";

import { RoleService } from "./service";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import history from '../../config/history';

const getOrganizationId = state => state.auth;  

export function* roleRequest() {
    while(true){
        var {params} = yield take(RoleActionTypes.ROLE_LIST_REQUEST);
      
        var role = yield call(RoleService.roleRequest,params);
        
        if(role.length > 0){
            yield put({
                type: RoleActionTypes.ROLE_LIST_SUCCESS,
                role_list: role
            })
        }
        else{
            yield put({
                type: RoleActionTypes.ROLE_LIST_FAILURE
            })
        }
    }
}

export function* assignRole(){
    while(true){
        const { data } = yield take(RoleActionTypes.ROLE_ADD_REQUEST);

        const params = {
            "umvaid": data.umvaid,
            "role": data.role,
            "org_id": data.org_id,
            "user_role_id": "user_role_id" in data  ? data.user_role_id : ""
        };
         
        var role = yield call(RoleService.assignRole, params);

        if(role.length > 0){
            toast.success("Role has been successfully assigned.", {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });

            yield put({
                type: RoleActionTypes.ROLE_ADD_SUCCESS,
                role_message: role
            })
            
            let org_id = yield select(getOrganizationId);
            
            var role = yield call(RoleService.roleRequest,{ "org_id": org_id.uid });

            if(role.length > 0){
                yield put({
                    type: RoleActionTypes.ROLE_LIST_SUCCESS,
                    role_list: role
                })
            }
            else{
                yield put({
                    type: RoleActionTypes.ROLE_LIST_FAILURE
                })
            }

            yield delay(2000);

            history.push(`/home`);
            history.push(`/setting/assign_role`);
        }
        else{
            yield put({
                type: RoleActionTypes.ROLE_ADD_FAILURE,
                role_message: role
            })
        }
    }
}

export function* roleListRequest(){
    while(true){
        yield take(RoleActionTypes.ROLE_REQUEST);

        var role_list = yield call(RoleService.roleListRequest);
        
        if(role_list.length > 0){
            yield put({
                type: RoleActionTypes.ROLE_SUCCESS,
                role: role_list
            })
        }
        else{
            yield put({
                type: RoleActionTypes.ROLE_ADD_FAILURE
            })
        }
    }
}

export function* roleDeleteRequest(){
    while(true){
        let {data} = yield take(RoleActionTypes.DELETE_ROLE_REQUEST);
        
        var role = yield call(RoleService.deleteRole, data);
    
        if(role == "Selected rows has been deleted."){
            toast.success("Role has been successfully deleted.", {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });

            yield put({
                type: RoleActionTypes.DELETE_ROLE_SUCCESS,
            })
            
            let org_id = yield select(getOrganizationId);
            
            var role = yield call(RoleService.roleRequest,{ "org_id": org_id.uid });

            if(role.length > 0){
                yield put({
                    type: RoleActionTypes.ROLE_LIST_SUCCESS,
                    role_list: role
                })
            }
            else{
                yield put({
                    type: RoleActionTypes.ROLE_LIST_FAILURE
                })
            }

            yield delay(2000);

            history.push(`/home`);
            history.push(`/setting/assign_role`);
        }
        else{
            yield put({
                type: RoleActionTypes.ROLE_ADD_FAILURE,
                role_message: role
            })
        }
    }
}

export default function* RoleSaga(){
    yield fork(roleRequest);
    yield fork(assignRole);
    yield fork(roleListRequest);
    yield fork(roleDeleteRequest);
}

