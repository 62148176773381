import Api from "../../config/api";
import errorParse from '../lib/error';

function activityRegister(params) {
    const res = Api.post('/activityData',params).then((response)=>{
        let data = response.data;
        return data;
    }).catch((error)=>{
        return errorParse(error);
    });
    return res;
}

function getCategory(params) {
    const res = Api.get('/getCategoryList', {params}).then((response)=>{
        let data = response.data;
        return data;
    }).catch((error)=>{
        return errorParse(error);
    });
    return res;
}

function getActivities(params) {
    const res = Api.get('/getActivitiesList', {params}).then((response)=>{
        let data = response.data;
        return data;
    }).catch((error)=>{
        return errorParse(error);
    });
    return res;
}

function deleteActivity(params) {
    const res = Api.get('/deleteSelectedActivity',{params}).then((response)=>{
        let data = response.data;
        return data;
    }).catch((error)=>{
        return errorParse(error);
    });
    return res;
}

function getActivityCategoryList(params){
    const res = Api.get('/activitySortingList',{params}).then((response)=>{
        let data = response.data;
        return data;
    }).catch((error)=>{
        return errorParse(error);
    });
    return res;
}

function updateActivityStatus(params){
    const res = Api.post('/updateActivityStatus',params).then((response)=>{
        let data = response.data;
        return data;
    }).catch((error)=>{
        return errorParse(error);
    });
    return res;
}

export const ActivityService = { 
  activityRegister,
  getCategory,
  getActivities,
  deleteActivity,
  getActivityCategoryList,
  updateActivityStatus
}