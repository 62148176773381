import React from 'react';
import Header from '../../../common/header';
import Breadcrumb from '../../../common/breadcrumb';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TextBox from '../../../common/textBox';
import Select from '../../../common/select';
import Radio from '../../../common/radio';

const Index = (props) => {
    var type= [
        "Generic","Specific"
    ];
    return (
        <div className="characteristicsContainer">
            <Header header={false} />

            <Breadcrumb />

            <div className="generalBody">
                <div className="formCard" style={{flexDirection: 'column'}}>
                    <div className="leftFormCard">
                        <TextBox 
                            label="Media Text" 
                        />

                        <TextBox 
                            label="Media Description" 
                        />

                        <TextBox 
                            label="Media Link" 
                        />

                        <TextBox 
                            label="Media Type" 
                        />
                        <Radio 
                            label1={"Activity"}
                            label2={"Pest And Disease"}
                            name="activity_pest"
                        />
                        
                        <Select 
                            label="Type" 
                            options={type}
                        />

                        <Select 
                            label="Crop" 
                        />

                        <Select
                            label="Activity" 
                        />  

                        <TextBox 
                            label="Load Media" 
                        />  
                    </div>

                    <div className="rightFormCard">
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        
    };
};
function mapDispatchtoProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
               
            },
            dispatch
        )
    };
}

export default connect(
    mapStateToProps,
    mapDispatchtoProps
)(Index);