const RoleActionTypes={
    ROLE_LIST_REQUEST: 'role_list_request',
    ROLE_LIST_SUCCESS: 'role_list_success',
    ROLE_LIST_FAILURE: 'role_list_failure',
    ROLE_SELECT_REQUEST: 'role_select_request',
    ROLE_ADD_REQUEST: 'role_add_request',
    ROLE_ADD_SUCCESS: 'role_add_success',
    ROLE_ADD_FAILURE: 'role_add_failure',
    ROLE_REQUEST: 'role_request',
    ROLE_SUCCESS: 'role_success',
    ROLE_FAILURE: 'role_failure',
    SELECT_PARTICULAR_ROLE_ASSIGNED_REQUEST: 'select_particular_role_assigned_request',
    DELETE_ROLE_REQUEST: 'delete_role_request',
    DELETE_ROLE_SUCCESS: 'delete_role_success',
}
export default RoleActionTypes;