import ActivityMediaActionTypes from './actionTypes';

import { fork, take, call, put, delay, select } from "redux-saga/effects";

import { ActivityMediaService } from './service';

import history from '../../../../config/history';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const getOrganisation = state => state.auth;

export function* activityList(){
    while(true){
        const { crop_id } = yield take(ActivityMediaActionTypes.CROP_ACTIVITY_MEDIA_LIST_REQUEST);

        let organisation = yield select(getOrganisation);
        
        if(crop_id.crop_id !== 0 ){
            var activity_list = yield call(ActivityMediaService.activityList,crop_id);
        }
        else{
            var activity_list = yield call(ActivityMediaService.genericActivityList, {crop_id: 0, organisation: organisation.organization});
        }

        var data = [...new Map(activity_list.map(item => [item.activity_id, item])).values()];

        if(Object.keys(activity_list).length > 0){
            yield put({
                type: ActivityMediaActionTypes.CROP_ACTIVITY_MEDIA_LIST_SUCCESS,
                activity_list: data,
            })
        }
        else{
            yield put({
                type: ActivityMediaActionTypes.CROP_ACTIVITY_MEDIA_LIST_SUCCESS,
                activity_list: [],
            })
        }
    }
}

export function* specificActivityMediaList() {
    while(true){
        const { data } = yield take(ActivityMediaActionTypes.SPECIFIC_CROP_ACTIVITY_LIST_REQUEST);

        var specific_activityMedia = yield call(ActivityMediaService.specificActivityMediaList, data);

        if(specific_activityMedia.length > 0) {
            yield put({
                type: ActivityMediaActionTypes.SPECIFIC_CROP_ACTIVITY_LIST_SUCCESS,
                specific_crop_activity_list: specific_activityMedia,
                specific_crop_activity_list1: specific_activityMedia,
            })
        }
        else{
            yield put({
                type: ActivityMediaActionTypes.SPECIFIC_CROP_ACTIVITY_LIST_SUCCESS,
                specific_crop_activity_list: specific_activityMedia,
                specific_crop_activity_list1: [],
            })
        }
    }
}

export function* genericActivityMediaList() {
    while(true){
        const { data } = yield take(ActivityMediaActionTypes.GENERIC_CROP_ACTIVITY_LIST_REQUEST);

        var specific_activityMedia = yield call(ActivityMediaService.genericActivityMediaList, data);

        if(specific_activityMedia.length > 0) {
            yield put({
                type: ActivityMediaActionTypes.GENERIC_CROP_ACTIVITY_LIST_SUCCESS,
                specific_crop_activity_list: specific_activityMedia
            })
        }
        else{
            yield put({
                type: ActivityMediaActionTypes.GENERIC_CROP_ACTIVITY_LIST_SUCCESS,
                specific_crop_activity_list: specific_activityMedia
            })
        }
    }
}

export function* specificActivityMediaList1() {
    while(true){
        const { data } = yield take(ActivityMediaActionTypes.SPECIFIC_CROP_ACTIVITY_LIST_REQUEST1);

        var specific_activityMedia = yield call(ActivityMediaService.specificActivityMediaList, data);
        
        if(specific_activityMedia.length > 0) {
            yield put({
                type: ActivityMediaActionTypes.SPECIFIC_CROP_ACTIVITY_LIST_SUCCESS1,
                specific_crop_activity_list: specific_activityMedia
            })
        }
        else{
            yield put({
                type: ActivityMediaActionTypes.SPECIFIC_CROP_ACTIVITY_LIST_SUCCESS,
                specific_crop_activity_list: specific_activityMedia
            })
        }
    }
}

export function* activityMediaRegister() {
    while(true){
        const { data } = yield take(ActivityMediaActionTypes.ACTIVITY_MEDIA_REGISTER_REQUEST);
        let organisation = yield select(getOrganisation);
        var activity_media = yield call(ActivityMediaService.registerActivityMedia,data);
        
        if(activity_media === "Activity Media is Saved"){
            var specific_activityMedia = yield call(ActivityMediaService.specificActivityMediaList, {
                "activity_id": data.activity_id,
                "crop_id": data.crop_id,
                organisation: organisation.organization,
            });

            if(specific_activityMedia.length > 0) { 
                yield put({
                    type: ActivityMediaActionTypes.SPECIFIC_CROP_ACTIVITY_LIST_SUCCESS,
                    specific_crop_activity_list: specific_activityMedia
                })
            }

            yield put({
                type: ActivityMediaActionTypes.ACTIVITY_MEDIA_REGISTER_SUCCESS,
            })
            
            toast.success("Activity Media has been added/updated successfully", {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });
            
            yield delay(2000);

            history.push(`/crop`);
            history.push(`/crop/activity_media`);
        }
        else{
            if("error" in activity_media){
                toast.error(activity_media.error.message, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });
            }
            yield put({
                type: ActivityMediaActionTypes.ACTIVITY_MEDIA_REGISTER_SUCCESS,
            })
        }
    }
}

export function* deleteActivityMedia(){
    while(true){
        const { data } = yield take(ActivityMediaActionTypes.DELETE_ACTIVITY_MEDIA_REQUEST);

        let organisation = yield select(getOrganisation);
        
        var deleted_data = yield call(ActivityMediaService.deleteActivityData,data);

        if(deleted_data === "Selected Media has been deleted." || deleted_data === "Selected rows have been deleted."){
            let all_media_list = yield call(ActivityMediaService.allMediaList,{"crop_id": data.crop_id, organisation: organisation.organization});
            
            if(all_media_list.length > 0){
                yield put({
                    type: ActivityMediaActionTypes.ALL_MEDIA_LIST_SUCCESS,
                    all_media_list: all_media_list
                })
            }
            else{
                yield put({
                    type: ActivityMediaActionTypes.ALL_MEDIA_LIST_SUCCESS,
                    all_media_list: []
                })
            }

            var specific_activityMedia = yield call(ActivityMediaService.specificActivityMediaList, {
                "activity_id": data.activity_id,
                "crop_id": data.crop_id,
                organisation: organisation.organization,
            });
        
            if(specific_activityMedia.length > 0) {
                yield put({
                    type: ActivityMediaActionTypes.SPECIFIC_CROP_ACTIVITY_LIST_SUCCESS,
                    specific_crop_activity_list: specific_activityMedia
                })
            }
            else{
                yield put({
                    type: ActivityMediaActionTypes.SPECIFIC_CROP_ACTIVITY_LIST_SUCCESS,
                    specific_crop_activity_list: specific_activityMedia
                })
            }

            toast.success("Activity Media has been deleted successfully.", {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });

            yield put({
                type: ActivityMediaActionTypes.DELETE_ACTIVITY_MEDIA_SUCCESS
            });

            yield delay(2000);

            history.push(`/crop`);
            history.push(`/crop/activity_media`);
        }
    }
}

export function* cropSpecificActivityRequest(){
    while(true){
        yield take(ActivityMediaActionTypes.CROP_SPECIFIC_ACTIVITY_REQUEST);

        let organisation = yield select(getOrganisation);

        var crop_specific_activity1 =  yield call(ActivityMediaService.cropSpecificActivityRequest, {organisation: organisation.organization});

        if(Object.keys(crop_specific_activity1).length > 0){
            var crop_specific_activity = Object.values(crop_specific_activity1).filter((tempData)=>{return tempData.generic === false});

            if(crop_specific_activity.length > 0){
                yield put({
                    type: ActivityMediaActionTypes.CROP_SPECIFIC_ACTIVITY_SUCCESS,
                    crop_specific_activity: crop_specific_activity
                })
            }
        }
    }
}

export function* allMediaListRequest(){
    while(true){
        const { crop_id } = yield take(ActivityMediaActionTypes.ALL_MEDIA_LIST_REQUEST);
        
        let organisation = yield select(getOrganisation);

        let all_media_list = yield call(ActivityMediaService.allMediaList,{"crop_id": crop_id, "organisation": organisation.organization});
        
        if(all_media_list.length > 0){
            yield put({
                type: ActivityMediaActionTypes.ALL_MEDIA_LIST_SUCCESS,
                all_media_list: all_media_list
            })
        }
        else{
            yield put({
                type: ActivityMediaActionTypes.ALL_MEDIA_LIST_SUCCESS,
                all_media_list: []
            })
        }
    }
}

export function* allGenericMediaListRequest(){
    while(true){
        const { crop_id } = yield take(ActivityMediaActionTypes.ALL_GENERIC_MEDIA_LIST_REQUEST);

        let organisation = yield select(getOrganisation);
        
        let all_media_list = yield call(ActivityMediaService.allGenericMediaList,{"crop_id": crop_id, organisation: organisation.organization});

        if(all_media_list.length > 0){
            yield put({
                type: ActivityMediaActionTypes.ALL_GENERIC_MEDIA_LIST_SUCCESS,
                all_media_list: all_media_list
            })
        }
        else{
            yield put({
                type: ActivityMediaActionTypes.ALL_GENERIC_MEDIA_LIST_SUCCESS,
                all_media_list: []
            })
        }
    }
}

export default function* ActivityMediaSaga(){
    yield fork(activityList);
    yield fork(specificActivityMediaList);
    yield fork(genericActivityMediaList);
    yield fork(activityMediaRegister);
    yield fork(deleteActivityMedia);
    yield fork(cropSpecificActivityRequest);
    yield fork(allMediaListRequest);
    yield fork(allGenericMediaListRequest);
    yield fork(specificActivityMediaList1);
}

