import RoleActionTypes from './actionTypes';

export function roleListRequest(params){
    return {
        type: RoleActionTypes.ROLE_LIST_REQUEST,
        params
    }
}

export function roleSelection(selected_role){
    return {
        type: RoleActionTypes.ROLE_SELECT_REQUEST,
        selected_role
    }
}

export function assignRole(data){
    return {
        type: RoleActionTypes.ROLE_ADD_REQUEST,
        data
    }
}

export function roleRequest(){
    return {
        type: RoleActionTypes.ROLE_REQUEST
    }
}

export function selectParticularAssignedRole(data){
    return {
        type: RoleActionTypes.SELECT_PARTICULAR_ROLE_ASSIGNED_REQUEST,
        data
    }
}

export function deleteRoleRequest(data){
    return {
        type: RoleActionTypes.DELETE_ROLE_REQUEST,
        data
    }
}
