import Api from '../../config/api';
import errorParse from '../lib/error';

function getUsageList(params) {
    const res = Api.get('/listUsages', {params}).then((response)=>{
        let data = response.data;
        return data;
    }).catch((error)=>{
        return errorParse(error);
    });
    return res;
}

function registerUsage(params) {
    const res = Api.post('/addUsage', params).then((response)=>{
        let data = response.data;
        return data;
    }).catch((error)=>{
        return errorParse(error);
    });
    return res;
}

function getCategoryList() {
    const res = Api.get('/getUsageCategory').then((response)=>{
        let data = response.data;
        return data;
    }).catch((error)=>{
        return errorParse(error);
    });
    return res;
}

function deleteUsageRequest(params) {
    const res = Api.get('/deleteUsage',{params}).then((response)=>{
        let data = response.data;
        return data;
    }).catch((error)=>{
        return errorParse(error);
    });
    return res;
}

function getVersions() {
    const res = Api.get('/getAgricoachVersion').then((response)=>{
        let data = response.data;
        return data;
    }).catch((error)=>{
        return errorParse(error);
    });
    return res;
}

export const UsageService = { 
    getUsageList,
    registerUsage,
    getCategoryList,
    deleteUsageRequest,
    getVersions,
}
