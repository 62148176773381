import React from 'react';
import { Link } from 'react-router-dom';
import './styles.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { languageUpdate } from '../trans/actions';
import Menu from '../components/lib/menu';

const Breadcrumb = props => {
    return (
        <div className="breadcrumbContainer">
            {
                (!props.activity && (props.displayVideo === false || "displayVideo" in props === false))&&
                    <div style={{paddingLeft: "30px",flex: 5,display: "flex",flexDirection: "row"}}>
                    {
                        Menu.map((menu)=>{ 
                            return (
                                window.location.pathname.includes(menu.menu) === true &&
                                    menu.subMenu.map((submenu,key)=>{
                                        return(
                                            props.selected_crop_id === 0 ?
                                            (submenu.id === 'activity_media' || submenu.id === 'crop_calendars') &&
                                                    <Link 
                                                        className="breadcrumb" 
                                                        to={`${submenu.id}`}
                                                        key={key}
                                                    >
                                                        <div 
                                                            className="breadcrumbText">
                                                            <span className={
                                                                window.location.pathname.includes(submenu.id) === true ? 'breadCrumbActive' : ''
                                                            }>
                                                                {submenu.label}
                                                            </span>
                                                        </div>
                                                    </Link>
                                            :
                                                menu.menu === "setting" ?
                                                    ((key === 0 || props.selected_crop_id !== "")) &&
                                                        <Link 
                                                            className="breadcrumb" 
                                                            to={`${submenu.id}`}
                                                            key={key}
                                                        >
                                                            <div 
                                                                className="breadcrumbText">
                                                                <span className={
                                                                    window.location.pathname.includes(submenu.id) === true ? 'breadCrumbActive' : ''
                                                                }>
                                                                    {props.displayCropImage === true ? "" : submenu.label}
                                                                </span>
                                                            </div>
                                                        </Link>
                                                :
                                                        <Link 
                                                            className="breadcrumb" 
                                                            to={`${submenu.id}`}
                                                            key={key}
                                                        >
                                                            <div 
                                                                className="breadcrumbText">
                                                                <span className={
                                                                    window.location.pathname.includes(submenu.id) === true ? 'breadCrumbActive' : ''
                                                                }>
                                                                    {props.displayCropImage === true ? "" : submenu.label}
                                                                </span>
                                                            </div>
                                                        </Link>
                                        )
                                    })
                            )
                        })
                    }
                    </div>
            }

            {
                props.save === true &&
                    <div 
                        className="saveContainer"
                        onClick={()=>{props.onSaveButtonClick()}}
                    >
                        <div>
                            <img 
                                style={{marginRight: 10}}
                                src={require('../images/save.png')} 
                                alt="save" 
                            />
                        </div>
                        <div>
                            <span>Save</span>
                        </div>
                    </div>
            }

            {
                props.displayVideo === true &&
                    <div 
                        className="saveContainer"
                        onClick={()=>{props.onDisplayVideoClick()}}
                    >
                        <div>
                            <span>
                                {
                                    props.videoVisibility === true 
                                    ? "Hide Videos"
                                    :   "Display Videos"
                                }
                            </span>
                        </div>
                    </div>
            }

            {
                props.displayCropImage === true &&
                    <div 
                        className="saveContainer"
                        onClick={()=>{props.onDisplayCropImageClick()}}
                    >
                        <div>
                            <span>
                                {
                                    props.cropImageVisibility === true 
                                    ? "Hide Image"
                                    :   "Display Image"
                                }
                            </span>
                        </div>
                    </div>
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
       lang: state.lang.language,
       selected_crop_id: state.crop.selected_crop_id,
    };
};

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                languageUpdate
            },
            dispatch
        )
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Breadcrumb);