const rn ={
    label: 'Kirundi',
    value: 'rn',
    translation: {
        "UMVA ID": "Umwidondoro muri UMVA",
        "Password": "Igiharuro c'ibanga",
        "Login": "Kwinjira muri porogaramu",
        "Logout": "Kuva muri porogaramu",
        "UMVA ID is required": "Umwidondoro UMVA Urasabwa",
        "User not exist": "Uwukoresha ntabaho",
        "Password is invalid": "igiharuro c'ibanga si co",
        "You must enter UMVA ID": "Mutegerezwa kwinjiza indangamuntu yo muri UMVA.",
        "You must enter a password": "Mutegereza gushiramwo igiharuro c'akabanga.",
        "Forgot your password?":"Mwibagiye igiharuro c'ibanga",
        "User does not exists": "Uwukoresha ntabaho",
        "Password is not correct": "igiharuro c'ibanga sico",
        "No Internet Connection": "Nta muhora wa enterineti uhari",
        "Need help": "Ubufasha burakenewe?",
        "English": "Icongereza",
        "French": "Igifaransa",
        "Kirundi": "Ikirundi",
        "Crops": "Igiterwa",
        "Pest and diseases": "Ivyonyi n'indwara",
        "Seasons": "Ibihe",
        "Bean goblets": " Igikombe bapimisha ibiharage",
    }
}
export default rn;