import React,{useEffect, useState} from 'react';
import Header from '../../../common/header';
import '../../crop/view/index.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TextBox from '../../../common/textBox';
import history from '../../../config/history';
import { roleRequest } from '../action';

const AddRole = (props) => {
    const [roleList, setRoleList] = useState(props.role);

    useEffect(()=>{
        setRoleList(props.role);
    },[props.role]);

    useEffect(()=>{
        props.actions.roleRequest();
    },[props.actions]);

    return (
        <div className="characteristicsContainer">
            <Header header={false} />

            <div className="generalBody">
                <div className="formCard" style={{flexDirection: 'column'}}>
                    <div className="selectionContainer">
                        <div 
                            className="selectionBodyContainer"
                            //onClick={()=>{history.push('/setting/add_role')}}
                            style={window.location.pathname.includes("add_role") === true ? {backgroundColor: '#E8E8E8',padding: 0,borderRadius:5} : {}}
                        >
                            <span>
                                <img 
                                    src={require('../../../images/Vector.png')} 
                                    alt="add_role" 
                                    style={{width: 15,height: 15,marginLeft: 3}}
                                />
                            </span>
                            <span>Add Role</span>
                        </div>
                        
                        <div 
                            className="selectionBodyContainer"
                            onClick={()=>{history.push('/setting/assign_role')}}
                            style={window.location.pathname.includes("assign_role") === true ? {backgroundColor: '#E8E8E8',borderRadius:5, cursor: "pointer"}: {}}
                        >
                            <span>
                                <img 
                                    src={require('../../../images/Group.png')} 
                                    alt="assign_role" 
                                    style={{width: 15,height: 15,marginLeft: 3}}
                                />
                            </span>
                            <span>Assign Role</span>
                        </div>
                    </div>

                    <div style={{display: 'flex',flexDirection: 'row',justifyContent:'space-between',width: "60%"}}>
                        <div className="flexDirectionColumn">                     
                            <TextBox 
                                label="Role" 
                            />
                        </div>

                        <div className="TextBoxContainer">
                            <div className="leftLabelContainer" style={{paddingRight: '10px'}}>
                                <span>Organization</span>
                            </div>

                            <div className="rightLabelContainer">
                                <div style={{display: "flex",flexDirection: 'column'}}>
                                    <select 
                                        className="selectInput"
                                    >
                                        <option>Organization</option>
                                        {
                                            props.organization_list.map((organization,key)=>{
                                                return (
                                                    <option 
                                                        key={key}
                                                        value={JSON.stringify(organization)}
                                                    >
                                                        {organization.organisation}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <table style={{width: "60%"}} className="tableContainer" cellSpacing="0">
                        <thead>
                            <tr>
                                {/* <th>
                                    <input type="checkbox" />
                                </th> */}
                                <th>Role</th>
                                {/* <th>Organization</th> */}
                                <th>
                                    <span>Edit</span> 
                                    {/* <span>
                                        <img 
                                             src={require('../../../images/edit_image.png')} 
                                             alt="edit" 
                                             style={{width: 15,height: 15,marginLeft: 3}}
                                        />
                                    </span> */}
                                </th>
                                <th>
                                    <span>Delete</span> 
                                    {/* <span>
                                        <img 
                                             src={require('../../../images/delete.png')} 
                                             alt="edit" 
                                             style={{width: 15,height: 15,marginLeft: 3}}
                                        />
                                    </span> */}
                                </th>
                            </tr>
                        </thead> 
                        <tbody>
                            {
                                roleList.length > 0 &&
                                    roleList.map((temp_data,key)=>{
                                        return (
                                            <tr key={key}>
                                                {/* <td>
                                                    <input type="checkbox" />
                                                </td> */}
                                                <td>{temp_data.role_type}</td>
                                                {/* <td>{}</td> */}
                                                <td style={{justifyContent: "center",alignContent: "center"}}>
                                                    <img 
                                                        src={require('../../../images/edit_image.png')} 
                                                        alt="edit" 
                                                        style={{cursor: "pointer"}}
                                                    />
                                                </td>
                                                <td style={{justifyContent: "center",alignContent: "center"}}>
                                                    <img 
                                                        src={require('../../../images/delete.png')} 
                                                        alt="edit" 
                                                        style={{width: 20,height: 20,cursor: "pointer"}}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        role: state.role.role,
        org_id: state.auth.uid,
        organization_list: state.organization.organization_list
    };
};
function mapDispatchtoProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                roleRequest
            },
            dispatch
        )
    };
}

export default connect(
    mapStateToProps,
    mapDispatchtoProps
)(AddRole);