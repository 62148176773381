import React, {useEffect, useState} from 'react';
import Header from '../../../../common/header';
import Breadcrumb from '../../../../common/breadcrumb';
import Spinner from '../../../../common/spinner';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import '../../../index.scss';
import {
    getCategory
} from '../../../activity/action';   
import {
    registerCropCalendarRequest,
    genericActivities,
    activityCalenderList
} from './action';
import CropCalendarTimeline from './cropCalendarTimeline';

const CropCalendars = props => {
    const [activityError, setActivityError] = useState(false);
    const [data, setData] = useState([]);
    const [cropActivity, setCropActivity] = useState([]);

    const getLangContent = (language) => {
        let data =  props.language_list.findIndex((tempData)=>{
            return tempData.locale.includes(language) === true
        })
        if(data !== -1){
            return data;
        }
        else{
            return 0;
        }
    }

    const getLanguageContent = (language) => {
        let data =  props.language_list.filter((tempData)=>{
            return tempData.locale.includes(language) === true
        })
        if(data.length > 0){
            return data[0].locale;
        }
        else{
            return "en_EN";
        }
    }

    useEffect(()=>{
        props.actions.getCategory();
    },[]);

    useEffect(()=>{
        if(props.selected_crop_id === 0){
            props.actions.genericActivities();
        }
        else{
            //props.actions.getCategory();
            //props.actions.activityCalenderList(props.selected_crop_id, props.organisation)
        }
    },[]);

    useEffect(()=>{
        setData(data);
    },[data]);

    useEffect(()=>{
        setCropActivity(cropActivity);
    },[cropActivity]);

    useEffect(()=>{
        setActivityError(activityError);
    },[activityError]);

    const textChange1 = (name, text, key, season_key) => {
        if(name === "activity_id"){
            let data0 = {};
            data0[name] = text;
            let data1 = {...data, ...data0}
            setData(data1);
            setActivityError(false);

            let selected_activity = props.crop_calendar_activity.filter((tempData)=>{
                return(tempData.activity_id == text)
            });
            
            if(selected_activity.length > 0){
                let data4 = [];
                let data3 = [];

                Object.values(selected_activity[0].seasons).map((tempData)=>{
                    let data2 = [];
                    data2.season_key = tempData.season;
                    data2.weeksAfter = tempData.weeksAfter;
                    data2.duration = tempData.duration;
                    data2.cropActivity_id = "";
                    if(tempData.duration !== "" || tempData.weeksAfter !== ""){
                        data3.push(tempData.cropactivity_id);
                    }
                    data4.push(data2);
                })  

                setCropActivity(data4);

                data3 = {"cropActivity_id": data3.join()};

                let crop_id = {"crop_id": props.selected_crop_id};
                let activity_id = {"activity_id": selected_activity[0].activity_id};
                let data5 = {...crop_id, ...activity_id, ...data3};
                setData(data5);
            }
            else{
                setCropActivity([]);
            }
        }
        else{
            let data0 = {};
            data0[name] = text;
            
            let data1 = {};
            let data3 = key in cropActivity && "season_key" in cropActivity[key] === false ? {"season_key": ""} : {}
            let data4 = {"season_key": season_key};
            data1[key] = {...cropActivity[key], ...data0, ...data3, ...data4};

            let data2 = {...cropActivity, ...data1};

            setCropActivity(data2);
        }
    }

    var langContent = getLangContent(props.lang);
    var languageContent = getLanguageContent(props.lang);

    return (
        <div>
            <Header header={false} />

            <Breadcrumb />

            <div className="generalBody"> 
                <div className="formCard" style={{flexDirection: 'column'}}> 
                    {
                        props.register_crop_calendar_loading === true || props.delete_crop_calendar_loading === true ?
                            <Spinner />
                        :
                            <>
                                {/* <div className="TextBoxContainer" style={{paddingBottom: 0}}>
                                    <div className="leftSelectLabelContainer1">
                                        <span>Activity</span>
                                    </div>

                                    <div className="rightSelectLabelContainer1">
                                        <div style={{display: "flex",flexDirection: 'column'}}>
                                            {
                                                activityError === true &&
                                                    <span className="errorText">{'This field is required'}</span>
                                            }
                                            <select 
                                                className="selectInput"
                                                name="activity_id"
                                                onChange={(e)=>{
                                                    textChange1("activity_id",e.target.value);
                                                }}
                                            >
                                                <option>Choose</option>
                                                {
                                                    props.selected_crop_id !== 0 ? 
                                                        props.activityCalenderList.length > 0 &&
                                                            props.activityCalenderList.map((tempData,key)=>{
                                                                return (
                                                                    <option key={key} value={tempData.id}>{
                                                                        tempData.activity_text.length > 0 ? 
                                                                            tempData.activity_text[props.language] !== undefined &&
                                                                                tempData.activity_text[props.language].locale === props.language1 ?
                                                                                    tempData.activity_text[props.language].name
                                                                                :
                                                                                    undefined
                                                                        :
                                                                            undefined
                                                                    }</option>
                                                                )
                                                            })
                                                    :
                                                        props.genericActivities.length > 0 && 
                                                            props.genericActivities.map((tempData,key)=>{
                                                                return (
                                                                    <option key={key} value={tempData.id}>{
                                                                        tempData.activity_text.length > 0 ?
                                                                            tempData.activity_text[props.language] !== undefined &&
                                                                                tempData.activity_text[props.language].locale === props.language1 ? 
                                                                                    tempData.activity_text[props.language].name
                                                                                :
                                                                                    undefined
                                                                        :
                                                                            undefined
                                                                    }</option>
                                                                )
                                                            })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div> */}
                                
                                {/* <CropCalendarTable 
                                    textChange={textChange1}
                                    data={data}
                                    cropActivity={cropActivity}
                                /> */}

                                <CropCalendarTimeline />
                            </>
                    }
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        lang: state.lang.language,
        selected_crop_id: state.crop.selected_crop_id,
        generic_category: state.activity.generic_category,
        crop_specific_category: state.activity.crop_specific_category,
        loading: state.cropCalendar.loading,
        register_crop_calendar_loading: state.cropCalendar.register_crop_calendar_loading,
        crop_calendar_activity: state.cropCalendar.crop_calendar_activity,
        delete_crop_calendar_loading: state.cropCalendar.delete_crop_calendar_loading,
        genericActivities: state.cropCalendar.genericActivities,
        activityCalenderList: state.cropCalendar.activityCalenderList,
        language_list: state.lang.language_list,
        organisation: state.auth.organization,
    };
};
function mapDispatchtoProps(dispatch) {
    return {
        actions: bindActionCreators(
            {   
                getCategory,
                registerCropCalendarRequest,
                genericActivities,
                activityCalenderList
            },
            dispatch
        )
    };
}

export default connect(
    mapStateToProps,
    mapDispatchtoProps
)(CropCalendars);