import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Modal from 'react-modal';

const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
    },
};

Modal.setAppElement('#root');

const Step1Modal = (props) => { 
    return (
        //props.user_role_type !== "Viewer" &&
            <Modal
                isOpen={ props.isModalOpen }
                onRequestClose={(e)=>{
                    e.stopPropagation();
                    e.preventDefault();
                }}
                style={customStyles}
            >
                <h4>Do you want to select/unselect a crop from other organisation ?</h4>
                <div className="modalBox">
                    <div className="leftModal">
                        <div 
                            className="modalButton"
                            onClick={(e)=>{
                                props.yesButtonClicked(e)
                            }}
                        >
                            Yes
                        </div>
                    </div>

                    <div className="rightModal">
                        <div 
                            className="modalButton" 
                            onClick={(e)=>{
                                props.noButtonClicked(e)
                            }}
                        >
                            No
                        </div>
                    </div>
                </div>
            </Modal>
    )
}

const mapStateToProps = (state) => {
    return {
        user_role_type: state.auth.user_role_type,
    };
};

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                
            },
            dispatch
        )
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Step1Modal);