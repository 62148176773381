import Api from '../../config/api';
import errorParse from '../lib/error';

function scaleTypeRequest(params) {
    const res = Api.get('/getScaleType', {params}).then((response)=>{
        let data = response.data;
        return data;
    }).catch((error)=>{
        return errorParse(error);
    });
    return res;
}

function scaleValueRequest(params) {
    const res = Api.get('/getScaleValues', {params}).then((response)=>{
        let data = response.data;
        return data;
    }).catch((error)=>{
        return errorParse(error);
    });
    return res;
}

function registerScaleValue(params) {
    const res = Api.post('/addScalesValues',params).then((response)=>{
        let data = response.data;
        return data;
    }).catch((error)=>{
        return errorParse(error);
    });
    return res;
}

function deleteScaleValues(params) {
    const res = Api.get('/deleteScaleValues',{ params }).then((response)=>{
        let data = response.data;
        return data;
    }).catch((error)=>{
        return errorParse(error);
    });
    return res;
}

function registerScaleTypes(params) {
    const res = Api.post('/addScaleTypes',params).then((response)=>{
        let data = response.data;
        return data;
    }).catch((error)=>{
        return errorParse(error);
    });
    return res;
}

function deleteScaleType(params) {
    const res = Api.get('/deleteScaleTypes',{ params }).then((response)=>{
        let data = response.data;
        return data;
    }).catch((error)=>{
        return errorParse(error);
    });
    return res;
}

export const ScaleValueService = { 
    scaleTypeRequest,
    scaleValueRequest,
    registerScaleValue,
    deleteScaleValues,
    registerScaleTypes,
    deleteScaleType
}
