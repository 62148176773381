import AuthActionTypes from './actionTypes';
import LanguageActionTypes from  '../../trans/actionTypes';

import { fork, take, call, put } from "redux-saga/effects";

import { AuthService } from './service';
import { RoleService } from '../role/service';

import history from "../../config/history";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function* loginUser() {
    while(true) { 
        const { params } = yield take( AuthActionTypes.AUTH_LOGIN_REQUEST);
        const { country, username, password, role } = params;

        const { data, error }  = yield call(AuthService.loginUser, {
            username: username,
            password: password,
            role: role
        });

        if(data !== undefined){
            const { token, user, roles: {type: role_type, user: superuser}, roles: role } = data;

            localStorage.setItem("token", token);
            localStorage.setItem("user", JSON.stringify(user));
            localStorage.setItem("country", country.toLowerCase());

            if(data){
                var umva_photo = "";

                yield put({
                    type: AuthActionTypes.AUTH_LOGIN_SUCCESS,
                    user: data,
                    umva_id: username,
                    loading: false,
                    isAuthenticated: true,
                    role_id: role,
                    uid: "",
                    user_role_type: role_type,
                    organization: "",
                    umva_photo: umva_photo,
                    superuser: superuser,
                });    

                var storage = {
                    umva_id: username,
                    user: data,
                    error: {},
                    loading: false,
                    isAuthenticated: true,
                    role_id: role,
                    uid: "",
                    user_role_type: role_type,
                    organization: "",
                    umva_photo: umva_photo,
                    superuser: superuser,
                };

                localStorage.setItem('user',JSON.stringify(storage));
                history.replace('/organization');
            }
            else{
                if(error){
                    toast.error(error.message.username, {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });

                    yield put({
                        type: AuthActionTypes.AUTH_LOGIN_FAIL,
                        user: null,
                        loading: false,
                        error: error
                    });
                }
            }
        }
        else{
            toast.error("Invalid UMVA ID or password", {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });

            yield put({
                type: AuthActionTypes.AUTH_LOGIN_FAIL,
                user: null,
                loading: false,
                error: error
            });
        }
    }
}

export function*languageRequest(){
    while(true){
        const { organization } = yield take( LanguageActionTypes.LANGUAGE_LIST_REQUEST );

        var language_list = yield call(AuthService.getLocale,{
            org_id: organization
        });

        if(language_list.length > 0){
            language_list = language_list.sort((a,b)=>(a.locale < b.locale) ? -1 : 1)

            let tmp_language_index = [];
            let tmp_language_list = [];

            language_list.map((tmp)=>{
                if(tmp_language_index.includes(tmp.locale) === false){
                    tmp_language_index.push(tmp.locale);
                    tmp_language_list.push(tmp);
                }
            })

            yield put({
                type: LanguageActionTypes.LANGUAGE_LIST_SUCCESS,
                language_list: tmp_language_list
            })
        }
    }
}

export function* userDetailRequest(){
    while(true){
        const { data } = yield take(AuthActionTypes.USER_DETAIL_REQUEST);

        const user_detail = yield call(AuthService.getDetailAll,{
            umva_id: data
        })

        var umva_photo = "";
        if(Object.keys(user_detail).length > 0){
            umva_photo = user_detail.result.user_info.umva_photo_jpg;
        }

        var storage = {
            umva_photo: umva_photo
        };

        localStorage.setItem('user',JSON.stringify(storage));

        yield put({
            type: AuthActionTypes.USER_DETAIL_SUCCESS,
            umva_photo: umva_photo
        })
    }
}

export function* organisationSelectRequest(){
    while(true){
        const {params} = yield take(AuthActionTypes.ORGANIZATION_SELECTION_REQUEST);
        let user = localStorage.getItem('user');
        user = JSON.parse(user);
        const {umva_id} = user;

        const {id, uid, organisation} = params;

        const roles = yield call(RoleService.getRole, {
            userid: umva_id,
            org_id: id
        });

        var errorMessage = {
            "message": {
                "company": "Role not assigned for the user."
            }
        };

        if(roles){
            if("status" in roles){
                yield put({
                    type: AuthActionTypes.ORGANIZATION_SELECTION_SUCCESS,
                    uid: "",
                    user_role_type: "",
                    organization: "",
                    superuser: "",
                    role_id: {},
                    error: errorMessage
                });

                toast.error(errorMessage.message.company, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });
            }
            else{
                if(Object.values(roles).length > 0){
                    const {role_type, superuser, userid} = roles;

                    var language_list = yield call(AuthService.getLocale,{
                        org_id: id
                    });

                    if(language_list.length > 0){
                        language_list = language_list.sort((a,b)=>(a.locale < b.locale) ? -1 : 1)
            
                        let tmp_language_index = [];
                        let tmp_language_list = [];
            
                        language_list.map((tmp)=>{
                            if(tmp_language_index.includes(tmp.locale) === false){
                                tmp_language_index.push(tmp.locale);
                                tmp_language_list.push(tmp);
                            }
                        })
            
                        yield put({
                            type: LanguageActionTypes.LANGUAGE_LIST_SUCCESS,
                            language_list: tmp_language_list
                        })

                        yield put({
                            type: AuthActionTypes.ORGANIZATION_SELECTION_SUCCESS,
                            uid: id,
                            user_role_type: role_type,
                            organization: organisation,
                            superuser: superuser,
                            role_id: {type: role_type, user: userid},
                            error: {}
                        });
                        
                        user.uid = id;
                        user.user_role_type = role_type;
                        user.organization = organisation;
                        user.superuser = superuser;
                        user.user_role_type =  role_type;
                        user.role_id = {type: role_type, user: userid};

                        localStorage.setItem('user',JSON.stringify(user));
                        history.replace('/home');
                    }
                    else{
                        yield put({
                            type: AuthActionTypes.ORGANIZATION_SELECTION_SUCCESS,
                            uid: "",
                            user_role_type: "",
                            organization: "",
                            superuser: "",
                            role_id: {},
                            error: {}
                        });
                    }   
                }
                else{
                    yield put({
                        type: AuthActionTypes.ORGANIZATION_SELECTION_SUCCESS,
                        uid: "",
                        user_role_type: "",
                        organization: "",
                        superuser: "",
                        role_id: {},
                        error: {}
                    });
                }
            }
        }
    }
}

export function* organizationResetRequest(){
    while(true){
        yield take(AuthActionTypes.ORGANIZATION_RESET_REQUEST);

        let user = localStorage.getItem('user');
        user = JSON.parse(user);

        yield put({
            type: AuthActionTypes.ORGANIZATION_RESET_SUCCESS,
            uid: "",
            user_role_type: "",
            organization: "",
            superuser: "",
            role_id: {},
            error: {}
        });

        user.uid = "";
        user.user_role_type = "";
        user.organization = "";
        user.superuser = "";
        user.role_id = {};

        localStorage.setItem('user',JSON.stringify(user));
        history.replace('/organization');
        window.location.reload();
    }
}

export default function* AuthSaga(){
    yield fork(loginUser);
    yield fork(languageRequest);
    yield fork(userDetailRequest);
    yield fork(organisationSelectRequest);
    yield fork(organizationResetRequest);
}

