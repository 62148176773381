import { applyMiddleware, createStore, compose } from "redux";

import Logger from "redux-logger";

import reducers from "./reducer";

import * as storage from "redux-storage";

import createSagaMiddleware from "redux-saga";
import saga from "./saga";

export default function configureStore(onComplete) {
    const sagaMiddleware = createSagaMiddleware();

    let store = process.env.REACT_APP_MODE === "DEBUG" ? 
        createStore(
            storage.reducer(reducers),
            compose(
                applyMiddleware(
                    sagaMiddleware,
                    Logger
                )
            )
        )
    :
        createStore(
            storage.reducer(reducers),
            compose(
                applyMiddleware(
                    sagaMiddleware
                )
            )
        );
    
    sagaMiddleware.run(saga);
    return store;
}
