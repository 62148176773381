import Api from "../../config/api";
import errorParse from '../lib/error';

function getWeatherHistory(params) {
    const res = Api.get('/getWeatherHistory', {params}).then((response)=>{
        let data = response.data;
        return data;
    }).catch((error)=>{
        return errorParse(error);
    });
    return res;
}

function getWatchCountReport(params) {
    const res = Api.get('/watchCount/Report', {params}).then((response)=>{
        let data = response.data;
        return data;
    }).catch((error)=>{
        return errorParse(error);
    });
    return res;
}

export const ReportService = { 
    getWeatherHistory,
    getWatchCountReport
}
