const config = {
    TEST_IMPLEMENTOR: {
        URL: process.env.REACT_APP_TEST_IMPLEMENTOR_URL,
        TOKEN: process.env.REACT_APP_TEST_IMPLEMENTOR_TOKEN 
    },
    TEST_AGRICOACH: {
        URL: process.env.REACT_APP_TEST_AGRICOACH_URL,
        TOKEN: process.env.REACT_APP_TEST_AGRICOACH_TOKEN
    },
    NEPAL_TEST_AGRICOACH: {
        URL: process.env.REACT_APP_NEPAL_TEST_AGRICOACH_URL,
        TOKEN: process.env.REACT_APP_NEPAL_TEST_AGRICOACH_TOKEN
    },
    IMPLEMENTOR: {
        URL: process.env.REACT_APP_IMPLEMENTOR_URL,  
        TOKEN: process.env.REACT_APP_IMPLEMENTOR_TOKEN
    },
    AGRICOACH: {
        URL: process.env.REACT_APP_AGRICOACH_URL,
        TOKEN: process.env.REACT_APP_AGRICOACH_TOKEN
    }
}
export default config;