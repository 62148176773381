const en ={
    label: 'English',
    value: 'en',
    translation: {
        "UMVA ID": "UMVA ID",
        "Password": "Password",
        "Login": "Login",
        "Logout": "Logout",
        "UMVA ID is required": "UMVA ID is required",
        "User not exist": "User not exist",
        "Password is invalid": "Password is not correct",
        "App title": "Key Activators and Auxfin personal have an account/password with which they can gain access to the Agricoach.",
        "You must enter UMVA ID": "You must enter UMVA ID.",
        "You must enter a password": "You must enter a password.",
        "Forgot your password?":"Forgot your password?",
        "User does not exists": "User does not exists",
        "Password is not correct": "Password is not correct.",
        "No Internet Connection": "No Internet Connection",
        "Need help": "Need help?",
        "English": "English",
        "French": "French",
        "Kirundi": "Kirundi",
        "Crops": "Crops",
        "Pest and diseases": "Pest and diseases",
        "Seasons": "Seasons",
        "Bean goblets": " Bean goblets",
    }
}
export default en;