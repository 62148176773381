import AuthActionTypes from './actionTypes';

const INITIAL_STATE = {
    umva_id: '',
    user: '',
    error: {},
    loading: false,
    isAuthenticated: false,
    role_id: '',
    uid: '',
    user_role_type: '',
    organization: '',
    umva_photo: '',
    superuser: '',
}

export default function(state=INITIAL_STATE, action){
    switch(action.type){
        case AuthActionTypes.AUTH_LOGIN_REQUEST:
            return {
                ...state,
                error: {},
                loading: true
            }
        case AuthActionTypes.AUTH_LOGIN_SUCCESS:
            return {
                ...state,
                user: action.user,
                loading:false,
                umva_id:action.umva_id,
                isAuthenticated: action.isAuthenticated,
                error:'',
                role_id:action.role_id,
                uid:action.uid,
                user_role_type: action.user_role_type,
                organization: action.organization,
                umva_photo: action.umva_photo,
                superuser: action.superuser,
            }
        case AuthActionTypes.AUTH_LOGIN_FAIL:
            return{
                ...state,
                error: action.error,
                loading: false
            }
        case AuthActionTypes.LOGIN_REFRESH:
            return {
                ...state,
                umva_id: action.data.umva_id,
                user: action.data.user,
                error: action.data.error,
                loading: action.data.loading,
                isAuthenticated: action.data.isAuthenticated,
                role_id: action.data.role_id,
                uid: action.data.uid,
                user_role_type: action.data.user_role_type,
                organization: action.data.organization,
                umva_photo: action.data.umva_photo,
                superuser: action.data.superuser,
            }
        case AuthActionTypes.USER_DETAIL_SUCCESS:
            return {
                ...state,
                umva_photo: action.umva_photo
            }
        case AuthActionTypes.ORGANIZATION_SELECTION_REQUEST:
            return {
                ...state,
                loading: true,
                error: {},
            }
        case AuthActionTypes.ORGANIZATION_SELECTION_SUCCESS:
            return {
                ...state,
                loading: false,
                uid: action.uid,
                user_role_type: action.user_role_type,
                organization: action.organization,
                superuser: action.superuser,
                role_id: action.role_id,
                error:action.error,
            }
        case AuthActionTypes.ORGANIZATION_RESET_REQUEST:
            return {
                ...state,
                loading: false,
                uid: action.uid,
                user_role_type: action.user_role_type,
                organization: action.organization,
                superuser: action.superuser,
                role_id: action.role_id,
                error:action.error,
            }
        default:
            return state;
    }
}