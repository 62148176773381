import MessageActionTypes from './actionTypes';

export function listMessageRequest(data){
    return {
        type: MessageActionTypes.MESSAGE_LIST_REQUEST,
        data
    }
}

export function deleteMessageRequest(data){
    return {
        type: MessageActionTypes.MESSAGE_DELETE_REQUEST,
        data
    }
}

export function particularMessageDetailRequest(data){
    return {
        type: MessageActionTypes.PARTICULAR_MESSAGE_REQUEST,
        data
    }
}

export function registerMessageRequest(params){
    return {
        type: MessageActionTypes.MESSAGE_REGISTER_REQUEST,
        params
    }
}

export function messagePublishUnpublish(data){
    return {
        type: MessageActionTypes.MESSAGE_PUBLISH_UNPUBLISH_REQUEST,
        data
    }
}