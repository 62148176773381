import GrowAreaActionTypes from './actionTypes';

const INITIAL_STATE = {
   grow_area_list: {},
   grow_area_list_loading: false,
   selected_grow_area: {},
   updateActivityMedia: 0,
   register_grow_area_loading: false,
   delete_grow_area_loading: false,
}

export default function(state = INITIAL_STATE, action){
    switch(action.type){
        case GrowAreaActionTypes.GROW_AREA_LIST_REQUEST:
            return {
                ...state,
                grow_area_list_loading: true
            }
        case GrowAreaActionTypes.GROW_AREA_LIST_SUCCESS:
            return {
                ...state,
                grow_area_list_loading: false,
                grow_area_list: action.grow_area_list
            }
        case GrowAreaActionTypes.GROW_AREA_LIST_FAILURE:
            return {
                ...state,
                grow_area_list_loading: false,
                grow_area_list: {}
            }
        case GrowAreaActionTypes.GROW_AREA_SELECT_REQUEST:
            return {
                ...state,
                selected_grow_area: action.selected_grow_area,
                updateActivityMedia: 0,
            }
        case GrowAreaActionTypes.REMOVE_SELECTED_GROW_AREA:
            return {
                ...state,
                selected_grow_area: {}
            }
        case GrowAreaActionTypes.REGISTER_GROW_AREA_REQUEST:
            return {
                ...state,
                register_grow_area_loading: true
            }
        case GrowAreaActionTypes.REGISTER_GROW_AREA_SUCCESS:
        case GrowAreaActionTypes.REGISTER_GROW_AREA_FAILURE:
            return {
                ...state,
                register_grow_area_loading: false
            }

        case GrowAreaActionTypes.DELETE_GROW_AREA_REQUEST:
            return {
                ...state,
                delete_grow_area_loading: true
            }
        case GrowAreaActionTypes.DELETE_GROW_AREA_SUCCESS:
            return {
                ...state,
                delete_grow_area_loading: false
            }
        default: 
            return state;
    }
}