export const GetOrganisationId = (organisation, offset) => {
    let data = Object.values(organisation).filter((tmp)=>{
        return tmp.organisation === offset;
    });
    if(data.length > 0){
        return data[0].id;
    }
    else{
        return 0;
    }
}
