import Api from "../../config/api";

function roleRequest(params) { 
    const res = Api.get('/users',{params}).then((response)=>{
        let data = response.data;
        return data;
    }).catch((error)=>{
        return error;
    });
    return res;
}

function assignRole(params){
    const res = Api.post('/assignRole',params).then((response)=>{
        let data = response.data;
        return data;
    }).catch((error)=>{
        return error;
    });
    return res;
}

function roleListRequest(){
    const res = Api.get('/listRole').then((response)=>{
        let data = response.data;
        return data;
    }).catch((error)=>{
        return error;
    });
    return res;
}

function deleteRole(params){
    const res = Api.get('/deleteUserRole',{params}).then((response)=>{
        let data = response.data;
        return data;
    }).catch((error)=>{
        return error;
    });
    return res;
}

function getRole(params){
    const res = Api.get('/getRole',{params}).then((response)=>{
        let data = response.data;
        return data;
    }).catch((error)=>{
        return {
            "status": "error",
            "message": error
        };
    });
    return res;
}

export const RoleService = { 
    roleRequest,
    assignRole,
    roleListRequest,
    deleteRole,
    getRole
}
