import { fork, take } from  "redux-saga/effects";

import AuthSaga from '../components/login/saga';
import CropSaga from '../components/crop/saga';
import OrganizationSaga from '../components/organization/saga';
import SeasonSaga from '../components/season/saga';
import GrowAreaSaga from '../components/growArea/saga';
import ActivitySaga from '../components/activity/saga';
import RoleSaga from '../components/role/saga';
import ActivityMediaSaga from '../components/crop/view/activity/saga';
import CropCalendarSaga from '../components/crop/view/cropCalendars/saga';
import ScaleValueSaga from '../components/scaleValue/saga';
import UsageSaga from '../components/usage/saga';
import MessageSaga from '../components/message/saga';
import ReportSaga from '../components/report/saga';

import { LOAD } from "redux-storage";

function *watchReduxLoadFromDisk() {
    while(true) {
        yield take(LOAD);
    }
}

export default function* Saga() {
    yield fork(watchReduxLoadFromDisk);
    yield fork(AuthSaga);
    yield fork(CropSaga);
    yield fork(OrganizationSaga);
    yield fork(SeasonSaga);
    yield fork(GrowAreaSaga);
    yield fork(RoleSaga);
    yield fork(ActivitySaga);
    yield fork(ActivityMediaSaga);
    yield fork(CropCalendarSaga);
    yield fork(ScaleValueSaga);
    yield fork(UsageSaga);
    yield fork(MessageSaga);
    yield fork(ReportSaga);
}