const ActivityMediaActionTypes = {
    CROP_ACTIVITY_MEDIA_LIST_REQUEST: 'activity.media.list.request',
    CROP_ACTIVITY_MEDIA_LIST_SUCCESS: 'activity.media.list.success',

    SPECIFIC_CROP_ACTIVITY_LIST_REQUEST: 'specific.crop.activity.list.request',
    SPECIFIC_CROP_ACTIVITY_LIST_SUCCESS: 'specific.crop.activity.list.succcess',

    GENERIC_CROP_ACTIVITY_LIST_REQUEST: 'generic_crop_activity_list_request',
    GENERIC_CROP_ACTIVITY_LIST_SUCCESS: 'generic_crop_activity_list_success',

    SPECIFIC_CROP_ACTIVITY_LIST_REQUEST1: 'specific.crop.activity.list.request1',
    SPECIFIC_CROP_ACTIVITY_LIST_SUCCESS1: 'specific.crop.activity.list.succcess1',

    REMOVE_SPECIFIC_ACTIVITY: 'remove_specific_activity',
    CLEAR_SELECTED_ACTIVITY_MEDIA: 'clear_selected_activity_media',

    ACTIVITY_MEDIA_REGISTER_REQUEST: 'activity_media_register_request',
    ACTIVITY_MEDIA_REGISTER_SUCCESS: 'activity_media_register_success',

    DELETE_ACTIVITY_MEDIA_REQUEST: 'delete_Activity_media_request',
    DELETE_ACTIVITY_MEDIA_SUCCESS: 'delete_activity_media_success',

    CROP_SPECIFIC_ACTIVITY_REQUEST: 'crop_specific_activity_request',
    CROP_SPECIFIC_ACTIVITY_SUCCESS: 'crop_specific_activity_success',

    ALL_MEDIA_LIST_REQUEST: 'all_media_list_request',
    ALL_MEDIA_LIST_SUCCESS: 'all_media_list_success',

    ALL_GENERIC_MEDIA_LIST_REQUEST: 'all_generic_media_list_request',
    ALL_GENERIC_MEDIA_LIST_SUCCESS: 'all_generic_meidia_list_success',

    SELECTED_GENERIC_ACTIVITY: 'selected_generic_activity',
    REMOVE_SELECTED_GENERIC_ACTIVITY: 'remove_selected_generic_Activity',
}
export default ActivityMediaActionTypes;