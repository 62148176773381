import AuthActionTypes from './actionTypes';

export function login(params) {
    return {
        type: AuthActionTypes.AUTH_LOGIN_REQUEST,
        loading: true,
        params
    }
}

export function logout() {
    return {
        type: AuthActionTypes.AUTH_LOGOUT_REQUEST,
        loading: true
    }
}

export function loginRefresh(data) {
    return {
        type: AuthActionTypes.LOGIN_REFRESH,
        data
    }
}

export function userDetailRequest(data) {
    return {
        type: AuthActionTypes.USER_DETAIL_REQUEST,
        data
    }
}

export function organisationSelectionRequest(params){
    return {
        type: AuthActionTypes.ORGANIZATION_SELECTION_REQUEST,
        params
    }
}

export function organizationResetRequest(){
    return {
        type: AuthActionTypes.ORGANIZATION_RESET_REQUEST,
    }
}