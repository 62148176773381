const UsageActionTypes={
    USAGE_LIST_REQUEST: 'usage_list_request',
    USAGE_LIST_SUCCESS: 'usage_list_success',

    USAGE_REGISTER_REQUEST: 'usage_register_request',
    USAGE_REGISTER_SUCCESS: 'usage_register_success',

    USAGE_DELETE_REQUEST: 'usage_delete_request',
    USAGE_DELETE_SUCCESS: 'usage_delete_success',

    PARTICULAR_USAGE_REQUEST: 'particular_usage_request',
    PARTICULAR_USAGE_SUCCESS: 'particular_usage_success',

    USAGE_CATEGORY_LIST_REQUEST: 'usage_category_list_request',
    USAGE_CATEGORY_LIST_SUCCESS: 'usage_category_list_success',

    VERSION_LIST_REQUEST: 'version_list_request',
    VERSION_LIST_SUCCESS: 'version_list_success',
}
export default UsageActionTypes;