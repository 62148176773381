import LanguageActionTypes from './actionTypes';

export function languageUpdate(language)
{
    return{
        type: LanguageActionTypes.LANGUAGE_UPDATE,
        language:language
    }

}

export function getLanguageList(organization){
    return {
        type: LanguageActionTypes.LANGUAGE_LIST_REQUEST,
        organization
    }
}