import MessageActionTypes from './actionTypes';

const INITIAL_STATE = {
   message_list_loading: false,
   message_register_loading: false,
   message_delete_loading: false,
   message_list: [],
   message_publish_unpublish_loading: false
}

export default function(state = INITIAL_STATE, action){
    switch(action.type){
        case MessageActionTypes.MESSAGE_LIST_REQUEST:
            return {
                ...state,
                message_list_loading: true
            }
        case MessageActionTypes.MESSAGE_LIST_SUCCESS:
            return {
                ...state,
                message_list_loading: false,
                message_list: action.message_list,
            }

        case MessageActionTypes.MESSAGE_REGISTER_REQUEST:
            return {
                ...state,
                message_register_loading: true
            }
        case MessageActionTypes.MESSAGE_REGISTER_SUCCESS:
            return {
                ...state,
                message_register_loading: false
            }

        case MessageActionTypes.MESSAGE_DELETE_REQUEST:
            return {
                ...state,
                message_delete_loading: true
            }

        case MessageActionTypes.MESSAGE_DELETE_SUCCESS:
            return {
                ...state,
                message_delete_loading: false
            }
        case MessageActionTypes.MESSAGE_PUBLISH_UNPUBLISH_REQUEST:
            return {
                ...state,
                message_publish_unpublish_loading: true
            }
        case MessageActionTypes.MESSAGE_PUBLISH_UNPUBLISH_SUCCESS:
            return {
                ...state,
                message_publish_unpublish_loading: false
            }
        default: 
            return state;
    }
}