import CropCalendarActionTypes from './actionTypes';

export function cropCalendarListRequest(data){
    return {
        type: CropCalendarActionTypes.CROP_CALENDAR_ACTIVITY_REQUEST,
        data
    }
}

export function selectedMedia(data){
    return {
        type: CropCalendarActionTypes.SELECTED_CROP_CALENDAR_MEDIA,
        data
    }
}

export function cropCalendarActivityEdit(data){
    return {
        type: CropCalendarActionTypes.CROP_CALENDAR_ACTIVITY_EDIT,
        data
    }
}

export function registerCropCalendarRequest(data, route){
    return {
        type: CropCalendarActionTypes.REGISTER_CROP_CALENDAR_ACTIVITY_REQUEST,
        data,
        route
    }
}

export function deleteActivityMedia(data, crop_id){
    return {
        type: CropCalendarActionTypes.DELETE_ACTIVITY_MEDIA_REQUEST,
        data,
        crop_id
    }
}

export function genericActivities(){
    return {
        type: CropCalendarActionTypes.GENERIC_ACTIVITIES_REQUEST
    }
}

export function activityCalenderList(crop_id, organisation){
    return {
        type: CropCalendarActionTypes.ACTIVITY_CALENDAR_LIST_REQUEST,
        crop_id,
        organisation
    }
}