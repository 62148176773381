import { combineReducers } from 'redux';
import AuthReducer from '../components/login/reducer';
import LanguageReducer from '../trans/reducer';
import CropReducer from '../components/crop/reducer';
import OrganizationReducer from '../components/organization/reducer';
import SeasonReducer from '../components/season/reducer';
import GrowAreaReducer from '../components/growArea/reducer';
import RoleReducer from '../components/role/reducer';
import ActivityReduer from '../components/activity/reducer';
import ActivityMediaReducer from '../components/crop/view/activity/reducer';
import CropCalendarReducer from '../components/crop/view/cropCalendars/reducer';
import ScaleValueReducer from '../components/scaleValue/reducer';
import UsageReducer from '../components/usage/reducer';
import MessageReducer from '../components/message/reducer';
import ReportReducer from '../components/report/reducer';

import AuthActionTypes from '../components/login/actionTypes';
import history from '../config/history';

const appReducer = combineReducers({
    auth: AuthReducer,
    lang: LanguageReducer,
    crop: CropReducer,
    organization: OrganizationReducer,
    season: SeasonReducer,
    growArea: GrowAreaReducer,
    role: RoleReducer,
    activity: ActivityReduer,
    activityMedia: ActivityMediaReducer,
    cropCalendar: CropCalendarReducer,
    scaleValue: ScaleValueReducer,
    usage: UsageReducer,
    message: MessageReducer,
    report: ReportReducer,
});

function index( state, action ) {
    if(action.type === AuthActionTypes.AUTH_LOGOUT_REQUEST){
        localStorage.removeItem('user');
        localStorage.removeItem('organization');
        localStorage.removeItem("token");
        localStorage.removeItem("access_token");
        localStorage.removeItem("country");
        state = undefined;
        history.replace('./');
    }
    return appReducer( state, action );
}
export default index;