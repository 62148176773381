import ActivityActionTypes from './actionTypes';

import { fork, take, call, put, delay, select } from "redux-saga/effects";

import { ActivityService } from './service';

import history from "../../config/history";   

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const getOrganisation = state => state.auth;

export function* registerActivity() {
    while(true) {
        const { params } = yield take( ActivityActionTypes.ACTIVITY_REGISTER_REQUEST);
        
        var activity = yield call(ActivityService.activityRegister, params);

        let organisation = yield select(getOrganisation);

        if(activity == "Activity is added."){           
            var category = yield call(ActivityService.getCategory,{organisation: organisation.organization});
            
            if(category.length > 0){
                var generic_category = category.filter((tempData)=>{return tempData.generic === true});
                var crop_specific_category = category.filter((tempData)=>{return tempData.generic === false});
                
                yield put({
                    type: ActivityActionTypes.CATEGORY_LIST_SUCCESS,
                    generic_category: generic_category,
                    crop_specific_category: crop_specific_category,
                })
            }

            if(organisation.organization){
                var activities = yield call(ActivityService.getActivities, {organisation: organisation.organization});

                if(Object.keys(activities).length > 0) {
                    yield put({
                        type: ActivityActionTypes.ACTIVITY_LIST_SUCCESS,
                        activity_list: activities
                    })
                }

                yield put({
                    type: ActivityActionTypes.ACTIVITY_REGISTER_SUCCESS
                })

                toast.success("Activity names and categories has been added/updated successfully", {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });
                
                yield delay(2000);

                history.push(`/home`);
                history.push(`/activity_names_and_categories`);
            }
        }
        else{
            yield put({
                type: ActivityActionTypes.ACTIVITY_REGISTER_SUCCESS
            })

            toast.error(activity.error.message, {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });
        }
    }
}

export function* getCategory() {
    while(true) {
        yield take(ActivityActionTypes.CATEGORY_LIST_REQUEST);
        let organisation = yield select(getOrganisation);
        var category = yield call(ActivityService.getCategory,{organisation: organisation.organization});
        
        if(category.length > 0){
            var generic_category = category.filter((tempData)=>{return tempData.generic === true});
            var crop_specific_category = category.filter((tempData)=>{return tempData.generic === false});
            
            yield put({
                type: ActivityActionTypes.CATEGORY_LIST_SUCCESS,
                generic_category: generic_category,
                crop_specific_category: crop_specific_category,
            })
        }
    }
}

export function* getActivity(){
    while(true){
        yield take(ActivityActionTypes.ACTIVITY_LIST_REQUEST);

        let organisation = yield select(getOrganisation);

        if(organisation.organization){
            var activities = yield call(ActivityService.getActivities,{organisation: organisation.organization});
            
            
            if(Object.keys(activities).length > 0) {
                yield put({
                    type: ActivityActionTypes.ACTIVITY_LIST_SUCCESS,
                    activity_list: activities
                })
            }
        }
    }
}

export function* deleteActivity(){
    while(true){
        const { activity_id } = yield take(ActivityActionTypes.DELETE_ACTIVITY_REQUEST);

        var activity_delete = yield call(ActivityService.deleteActivity, {activity_id});

        let organisation = yield select(getOrganisation);
        
        if(activity_delete === "Selected rows has been deleted."){
            var category = yield call(ActivityService.getCategory,{organisation: organisation.organization});
            
            if(category.length > 0){
                var generic_category = category.filter((tempData)=>{return tempData.generic === true});
                var crop_specific_category = category.filter((tempData)=>{return tempData.generic === false});
                
                yield put({
                    type: ActivityActionTypes.CATEGORY_LIST_SUCCESS,
                    generic_category: generic_category,
                    crop_specific_category: crop_specific_category,
                })
            }

            if(organisation.organization){
                var activities = yield call(ActivityService.getActivities, {organisation: organisation.organization});

                if(Object.keys(activities).length > 0) {
                    yield put({
                        type: ActivityActionTypes.ACTIVITY_LIST_SUCCESS,
                        activity_list: activities
                    })
                }
                
                yield put({
                    type: ActivityActionTypes.DELETE_ACTIVITY_SUCCESS
                })

                toast.success("Selected data has been deleted successfully", {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });
            }
        }
    }
}

export function* activityCategoryList(){
    while(true){
        const { category } = yield take(ActivityActionTypes.ACTIVITY_SORTING_LIST_REQUEST);
        
        var category_list = yield call(ActivityService.getActivityCategoryList, category);
        
        if(category_list.length > 0){
            yield put({
                type: ActivityActionTypes.ACTIVITY_SORTING_LIST_SUCCESS,
                activity_category_list: category_list
            })
        }
        else{
            yield put({
                type: ActivityActionTypes.ACTIVITY_SORTING_LIST_SUCCESS,
                activity_category_list: category_list
            })
        }
    }
}

export function* updateActivityStatus(){
    while(true){
        const { params } = yield take(ActivityActionTypes.UPDATE_ACTIVITY_STATUS_REQUEST);
    
        var status = yield call(ActivityService.updateActivityStatus, params);

        let organisation = yield select(getOrganisation);

        if(status.includes("status is set to ")){
            if(organisation.organization){
                var activities = yield call(ActivityService.getActivities, {organisation: organisation.organization});

                if(Object.keys(activities).length > 0) {
                    yield put({
                        type: ActivityActionTypes.ACTIVITY_LIST_SUCCESS,
                        activity_list: activities
                    })

                    yield put({
                        type: ActivityActionTypes.UPDATE_ACTIVITY_STATUS_SUCCESS,
                    });
        
                    toast.success("Activity published/unpublished Successfully", {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
            }
        }
        else{
            yield put({
                type: ActivityActionTypes.UPDATE_ACTIVITY_STATUS_SUCCESS,
            });
            toast.error(status.error.message, {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });
        }
    }
}

export default function* ActivitySaga(){
    yield fork(registerActivity);
    yield fork(getCategory);
    yield fork(getActivity);
    yield fork(deleteActivity);
    yield fork(activityCategoryList);
    yield fork(updateActivityStatus);
}   

