import React, { useEffect } from 'react';
import { Router, Switch, Route, BrowserRouter } from 'react-router-dom';
import history from './history';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { loginRefresh, userDetailRequest } from '../components/login/action';
import { getLanguageList } from '../trans/actions';
import { languageUpdate } from '../trans/actions';

import Login from '../components/index';
import Landing from '../components/landing/index';
import LandingOrganization from '../components/landing/LandingOrganization';
import Crop from '../components/crop/index';
import Organization from '../components/organization/index';
import Season from '../components/season/index';
import GrowArea from '../components/growArea/index';
import ScaleValue from '../components/scaleValue/index';
import Language from '../components/language/index';
import PestDiseases from '../components/pestDiseases/index';
import Activity from '../components/activity/index';
import Media from '../components/media/index';
import Role from '../components/role/index';
import Usage from '../components/usage/index';
import Message from '../components/message/index';
import Report from '../components/report/index';

const Approuter = props => {
    useEffect(() => {
        var user = localStorage.getItem('user');
        var lang = localStorage.getItem('i18nextLng');
        var token = localStorage.getItem('token');

        props.actions.languageUpdate(lang);

        if (token) {
            let user_data = JSON.parse(user);
            const { uid } = user_data;

            props.actions.loginRefresh(JSON.parse(user));
            if (uid !== "") {
                history.push("/home");
            }
            else {
                history.push("/organization");
            }

            if (props.umva_photo === "") {
            }
            else {
                props.actions.loginRefresh(JSON.parse(user));
            }
        }
        else {
            localStorage.removeItem('user');
            localStorage.removeItem('i18nextLng');
            localStorage.removeItem('organization');
            history.push("/");
        }

        if (JSON.parse(localStorage.getItem('user')) !== null) {
            props.actions.getLanguageList(JSON.parse(localStorage.getItem('user')).uid);
        }
    }, [props.actions]);

    return (
        <BrowserRouter>
            <Router history={history}>
                <Switch>
                    <Route exact path="/" component={Login} />
                    <Route path="/organization" component={LandingOrganization} />
                    <Route path="/home" component={Landing} />
                    <Route path="/crop" component={Crop} />
                    <Route path="/setting/organization" component={Organization} />
                    <Route path="/season" component={Season} />
                    <Route path="/grow_area" component={GrowArea} />
                    <Route path="/scale_value" component={ScaleValue} />
                    <Route path="/setting/language" component={Language} />
                    <Route path="/pest_diseases" component={PestDiseases} />
                    <Route path="/activity_names_and_categories" component={Activity} />
                    <Route path="/media" component={Media} />
                    <Route path="/setting" component={Role} />
                    <Route path="/guide" component={Usage} />
                    <Route path="/message" component={Message} />
                    <Route path="/report" component={Report} />
                </Switch>
            </Router>
        </BrowserRouter>
    )
}

const mapStateToProps = state => {
    return {
        umva_id: state.auth.umva_id,
        umva_photo: state.auth.umva_photo,
        uid: state.auth.uid,
    };
};
function mapDispatchtoProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                loginRefresh,
                languageUpdate,
                getLanguageList,
                userDetailRequest
            },
            dispatch
        )
    };
}

export default connect(
    mapStateToProps,
    mapDispatchtoProps
)(Approuter);