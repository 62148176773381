import React from 'react';
import { Route } from 'react-router-dom';
import CropCalendars from './cropCalendars';
import CropCalendarsMedia from './cropCalendarMedia';
import CropCalendarsEdit from './cropCalendarEdit';

const Calendar = (props) => {
    return (
       <>
            <Route
                exact
                path={`${props.match.path}`}
                component={CropCalendars}
            />

            <Route 
                path={`${props.match.path}/media`}
                component={CropCalendarsMedia}
            />

            <Route 
                path={`${props.match.path}/edit`}
                component={CropCalendarsEdit}
            />  
       </>
    )
}

export default Calendar;