const GetLatitudeLongitude = (organisation_list, organisation) => {
    let selected_organisation_list = Object.values(organisation_list).filter((tmp)=>{return tmp.organisation === organisation});
    if(selected_organisation_list.length > 0){
        return selected_organisation_list[0];
    }
    else{
        return {
            "latitude": 0,
            "longitude": 0
        };
    } 
}

export default GetLatitudeLongitude;