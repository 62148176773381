import CropActionTypes from './actionTypes';

const INITIAL_STATE = {
    crop_list: [],
    loading: false,
    selected_crop_detail: {},
    selected_crop_detail_loading: false,
    selected_crop_id: '',
    season_list: {},
    grow_area_list: {},
    selected_variety: {},
    selected_fertilizer: {},
    descriptionData: {},
    description: {},
    description_media: {},
    description_media_loading: false,
    description_loading: false,
    mediaData: {},
    //characteristics
    characteristicsData: {},
    characteristics_loading: false,
    //indicator
    indicatorData: [],
    indicator_loading: false,
    //pest and diseases
    pestDiseasesData: [],
    pest_diseases_loading: false,
    pest_diseases_list: {},
    pest_diseases_list_loading: false,
    selected_pest_diseases: [],
    pest_diseases_media_list: [],
    pest_diseases_media_list_loading: false,
    selected_pest_diseases_media: [],
    pest_diseases_media_register_loading: false,
    delete_pest_diseases_loading: false,
    //general
    crop_media_list: [],
    crop_media_list_loading: false,
    selected_crop_media: [], 
    //crop
    crop_register_loading: false,
    crop_delete: false,
    base_org_crop_list: [],
    base_org_crop_list_loading: false,
    crop_copy_loading: false,
    //variety
    variety_register_loading: false,
    variety_delete_loading: false,
    //fertilizer
    fertilizer_register_loading: false,
    feretilizer_delete_loading: false,
    publish_unpublish_loading: false,
    publish_message: '',
    selected_crop_list: [],
    selected_crop_list_counter: 0,
    //location
    latitude: "",
    longitude: "",
}

export default function(state = INITIAL_STATE, action){
    switch(action.type){
        case CropActionTypes.CROP_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case CropActionTypes.CROP_SUCCESS:
            return {
                ...state,
                loading: action.loading,
                crop_list: action.crop_list,
                season_list: action.season_list,
                grow_area_list: action.grow_area_list
            }
        case CropActionTypes.CROP_FAILURE:
            return {
                ...state,
                loading: false,
                crop_list: {}
            }
        case CropActionTypes.PARTICULAR_CROP_DETAIL_REQUEST: 
            return {
                ...state,
                selected_crop_detail_loading: true,
                selected_variety: [],
                crop_media_list: [],
                latitude: "",
                longitude: "",
            }
        case CropActionTypes.PARTICULAR_CROP_DETAIL_SUCCESS: 
            return {
                ...state,
                selected_crop_detail: action.selected_crop_detail,
                selected_crop_detail_loading: action.selected_crop_detail_loading,
                selected_crop_id: action.selected_crop_id,
                selected_crop_media: [],
            }
        case CropActionTypes.CLEAR_CROP_DETAIL:
            return {
                ...state,
                selected_crop_detail: {},
                selected_crop_detail_loading: false,
                selected_crop_id: ''
            }
        case CropActionTypes.SELECT_VARIETY_REQUEST:
            return {
                ...state,
                selected_variety: action.selected_data
            }
        case CropActionTypes.SELECT_FERTILIZER_REQUEST:
            return {
                ...state,
                selected_fertilizer: action.selected_data
            }
        case CropActionTypes.SET_DESCRIPTION_DATA:
            return {
                ...state,
                descriptionData: action.data
            }
        case CropActionTypes.REGISTER_DESCRIPTION_REQUEST:
            return {
                ...state,
                description_loading: true
            }
        case CropActionTypes.REGISTER_DESCRIPTION_SUCCESS:
            return {
                ...state,
                description_loading: false
            }
        case CropActionTypes.REGISTER_DESCRIPTION_FAILURE:
            return {
                ...state,
                description_loading: false
            }
        case CropActionTypes.REMOVE_DESCRIPTION_DATA:
            return {
                ...state,
                descriptionData: {}
            }
        case CropActionTypes.SET_MEDIA_DATA:
            return {
                ...state,
                mediaData: action.data
            }
        case CropActionTypes.REMOVE_MEDIA_DATA:
            return {
                ...state,
                mediaData: {}
            }
        case CropActionTypes.REGISTER_DESCRIPTION_MEDIA_REQUEST:
            return {
                ...state,
                description_media_loading: true,
            }
        case CropActionTypes.REGISTER_DESCRIPTION_MEDIA_SUCCESS:
            return {
                ...state,
                description_media_loading: false,
            }
        case CropActionTypes.REGISTER_DESCRIPTION_MEDIA_FAILURE:
            return {
                ...state,
                description_media_loading: false,
            }
        //characteristics
        case CropActionTypes.SET_CHARACTERISTICS_DATA:
            return {
                ...state,
                characteristicsData: action.data
            }
        case CropActionTypes.REMOVE_CHARACTERISTICS_DATA: 
            return {
                ...state,
                characteristicsData: {}
            }
        case CropActionTypes.REGISTER_CHARACTERISTICS_DATA_REQUEST:
            return {
                ...state,
                characteristics_loading: true,
            }
        case CropActionTypes.REGISTER_CHARACTERISTICS_DATA_SUCCESS:
        case CropActionTypes.REGISTER_CHARACTERISTICS_DATA_FAILURE:
            return {
                ...state,
                characteristics_loading:false,
            }

        //indicator 
        case CropActionTypes.SET_INDICATOR_DATA:
            return {
                ...state,
                indicatorData: action.data
            }
        case CropActionTypes.REMOVE_INDICATOR_DATA: 
            return {
                ...state,
                indicatorData: []
            }
        case CropActionTypes.REGISTER_INDICATOR_DATA_REQUEST:
            return {
                ...state,
                indicator_loading: true,
            }
        case CropActionTypes.REGISTER_INDICATOR_DATA_SUCCESS:
        case CropActionTypes.REGISTER_INDICATOR_DATA_FAILURE:
            return {
                ...state,
                indicator_loading:false,
            }

        //pest and diseases
        case CropActionTypes.SET_PEST_DISEASES_DATA:
            return {
                ...state,
                pestDiseasesData: action.data
            }
        case CropActionTypes.REMOVE_PEST_DISEASES_DATA: 
            return {
                ...state,
                pestDiseasesData: []
            }
        case CropActionTypes.REGISTER_PEST_DISEASES_DATA_REQUEST:
            return {
                ...state,
                pest_diseases_loading: true,
            }
        case CropActionTypes.REGISTER_PEST_DISEASES_DATA_SUCCESS:
        case CropActionTypes.REGISTER_PEST_DISEASES_DATA_FAILURE:
            return {
                ...state,
                pest_diseases_loading:false,
            }
        case CropActionTypes.PEST_DISEASES_LIST_REQUEST:
            return {
                ...state,
                pest_diseases_list_loading: true,
            }
        case CropActionTypes.PEST_DISEASES_LIST_SUCCESS:
            return {
                ...state,
                pest_diseases_list: action.list,
                pest_diseases_list_loading: false,
                selected_pest_diseases: state.selected_pest_diseases
            }
        case CropActionTypes.SELECT_PEST_DISEASES:
            return {
                ...state,
                selected_pest_diseases: action.data
            }
        case CropActionTypes.PEST_DISEASES_MEDIA_REQUEST:
            return {
                ...state,
                pest_diseases_media_list_loading: true,
            }
        case CropActionTypes.PEST_DISEASES_MEDIA_SUCCESS:
        case CropActionTypes.PEST_DISEASES_MEDIA_FAILURE:
            return {
                ...state,
                pest_diseases_media_list: action.data,
                pest_diseases_media_list_loading: false,
                selected_pest_diseases: state.selected_pest_diseases
            }
        case CropActionTypes.SELECT_PEST_DISEASES_MEDIA:
            return {
                ...state,
                selected_pest_diseases_media: action.data
            }
        case CropActionTypes.REGISTER_PEST_DISEASES_MEDIA_REQUEST:
            return {
                ...state,
                pest_diseases_media_register_loading: true
            }
        case CropActionTypes.REGISTER_PEST_DISEASES_MEDIA_SUCCESS:
        case CropActionTypes.REGISTER_PEST_DISEASES_MEDIA_FAILURE:
            return {
                ...state,
                pest_diseases_media_register_loading: false,
                selected_pest_diseases: action.selected_pest_diseases
            }

        case CropActionTypes.DELETE_PEST_DISEASES_REQUEST:
            return {
                ...state,
                delete_pest_diseases_loading: true,
            }
        case CropActionTypes.DELETE_PEST_DISEASES_SUCCESS:
        case CropActionTypes.DELETE_PEST_DISEASES_FAILURE:
            return {
                ...state,
                delete_pest_diseases_loading: false
            }
        
        //general
        case CropActionTypes.SELECT_CROP_MEDIA:
            return {
                ...state,
                selected_crop_media: action.data
            }
        case CropActionTypes.CROP_MEDIA_LIST_REQUEST:
            return {
                ...state,
                crop_media_list_loading: true
            }
        case CropActionTypes.CROP_MEDIA_LIST_SUCCESS:
            return {
                ...state,
                crop_media_list: action.media_list,
                crop_media_list_loading: false,
                selected_crop_media: []
            }

        //crop
        case CropActionTypes.CROP_REGISTER_REQUEST:
            return {
                ...state,
                crop_register_loading: true,
            }
        case CropActionTypes.CROP_REGISTER_SUCCESS:
        case CropActionTypes.CROP_REGISTER_FAILURE:
            return {
                ...state,
                crop_register_loading:false,
            }
        case CropActionTypes.DELETE_MEDIA_REQUEST:
            return {
                ...state,
                crop_delete: true
            }
        case CropActionTypes.DELETE_MEDIA_SUCCESS:
        case CropActionTypes.DELETE_MEDIA_FAILURE:
            return {
                ...state,
                crop_delete: false
            }

        case CropActionTypes.GET_BASE_ORGANISATION_CROP_LIST_REQUEST:
            return {
                ...state,
                base_org_crop_list_loading: true
            }
        case CropActionTypes.GET_BASE_ORGANISATION_CROP_LIST_SUCCESS:
            return {
                ...state,
                base_org_crop_list_loading: false,
                base_org_crop_list: action.crop_list
            }
        case CropActionTypes.GET_BASE_ORGANISATION_CROP_LIST_REQUEST:    
            return{
                ...state,
                crop_copy_loading: true
            }
        case CropActionTypes.GET_BASE_ORGANISATION_CROP_LIST_SUCCESS:    
            return{
                ...state,
                crop_copy_loading: false
            }

        case CropActionTypes.CROP_COPY_REQUEST:
            return {
                ...state,
                crop_copy_loading: true,
            }
        case CropActionTypes.CROP_COPY_SUCCESS:
            return {
                ...state,
                crop_copy_loading: false,
            }
        //variety
        case CropActionTypes.VARIETY_REGISTER_REQUEST:
            return {
                ...state,
                variety_register_loading: true,
            }
        case CropActionTypes.VARIETY_REGISTER_SUCCESS:
        case CropActionTypes.VARIETY_REGISTER_FAILURE:
            return {
                ...state,
                variety_register_loading:false,
            }
        case CropActionTypes.DELETE_VARIETY_REQUEST:
            return {
                ...state,
                variety_delete_loading: true,
            }
        case CropActionTypes.DELETE_VARIETY_SUCCESS:
        case CropActionTypes.DELETE_VARIETY_FAILURE:
            return {
                ...state,
                variety_delete_loading:false,
            }

        //fertilizer
        case CropActionTypes.REGISTER_FERTILIZER_REQUEST:
            return {
                ...state,
                fertilizer_register_loading: true,
            }
        case CropActionTypes.REGISTER_FERTILIZER_SUCCESS:
        case CropActionTypes.REGISTER_FERTILIZER_FAILURE:
            return {
                ...state,
                fertilizer_register_loading:false,
            }

        case CropActionTypes.DELETE_FERTILIZER_REQUEST:
                return {
                    ...state,
                    feretilizer_delete_loading: true,
                }
        case CropActionTypes.DELETE_FERTILIZER_SUCCESS:
        case CropActionTypes.DELETE_FERTILIZER_FAILURE:
            return {
                ...state,
                feretilizer_delete_loading:false,
            }
        case CropActionTypes.CROP_PUBLISH_UNPUBLISH_REQUEST:
            return {
                ...state,
                publish_unpublish_loading: true,
                publish_message: '',
            }
        case CropActionTypes.CROP_PUBLISH_UNPUBLISH_SUCCESS:
            return {
                ...state,
                publish_unpublish_loading: false,
                publish_message: action.publish_message
            }
        case CropActionTypes.CROP_SELECT_UNSELECT_REQUEST:
            return {
                ...state,
                selected_crop_list: action.data,
                selected_crop_list_counter: state.selected_crop_list_counter + 1,
            }
        //location
        case CropActionTypes.SET_LATITUDE_AND_LONGITUDE:
            return {
                ...state,
                latitude: action.params.latitude,
                longitude: action.params.longitude,
            }
        default: 
            return state;
    }
}