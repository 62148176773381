import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import './styles.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { languageUpdate } from '../trans/actions';
import I18n from '../trans/i18n';
import { clearCropDetail } from '../components/crop/action';
import history from '../config/history';
import {GetToOrganisation} from '../components/lib/getToOrganisation';

import Step1Modal from './step1Modal';
import Step2Modal from './step2Modal';

const Header = (props) => {
    const [step1Modal, setStep1Modal] = useState(false);
    const [step2Modal, setStep2Modal] = useState(false);
    const [cropStatus, setCropStatus] = useState('');
    const to_organisation = GetToOrganisation(props.mapping_list, props.auth);

    useEffect(()=>{
        setCropStatus(cropStatus);
    },[cropStatus]);

    useEffect(()=>{
        if(props.selected_crop_id !== ""){
            if(props.selected_crop_id !== 0){
                let status = props.crop_list.filter((data)=>{
                    return data.id === props.selected_crop_id
                })
                setCropStatus(status[0].crop_settings[0].status);
            }
        }
    },[]);

    const changeLanguage = (lang) => {
        localStorage.setItem('i18nextLng', lang);
        I18n.changeLanguage(localStorage.getItem('i18nextLng'));
        props.actions.languageUpdate(lang);
    }

    const getLangContent = (language) => {
        let data =  props.language_list.findIndex((tempData)=>{
            return tempData.locale.includes(language) === true
        })
        if(data !== -1){
            return data;
        }
        else{
            return 0;
        }
    }

    var langContent = getLangContent(props.lang);

    const noButtonClicked = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setStep1Modal(false);
        setStep2Modal(false);
        history.push(`/crop/detail`)
    }

    const yesButtonClicked = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setStep1Modal(false);
        setStep2Modal(true);
    }

    const closeModal = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setStep1Modal(false);
        setStep2Modal(false);
        history.push(`/crop`)
    }

    const getEnLanguageIndex = (data) => {
        if(Object.values(data).length > 0){
            let index = Object.values(data).findIndex((tmp)=>{return tmp.locale === "en_EN"});

            if(index !== -1)
                return index;
            else    
                return 0;
        }
        else
            return 0;
    }

    return (
        <div className="cropHeaderContainer">
            <Link 
                className="cropBackContainer"
                to={window.location.pathname.includes("/crop/") === true ? 
                    window.location.pathname.includes("/crop/crop_calendars/edit") === true || window.location.pathname.includes("/crop/crop_calendars/media") === true  ?
                        "/crop/crop_calendars"
                    :
                        "/crop" 
                : 
                    "/home"}
                onClick={()=>{
                    window.location.pathname.includes("/crop/crop_calendars/") === true ?
                        history.goBack()
                    :
                        props.actions.clearCropDetail()
                }}
            >
                <img 
                    src={require("../images/back.png")}
                    alt="back"
                />
                
            </Link>

            {
                props.user_role_type !== "Viewer" &&
                    to_organisation === props.auth &&
                        props.header === true ?
                            !"modalIsOpen" in props ?
                                <div
                                    className="cropTitleContainer1"
                                    onClick={()=>{setStep1Modal(true)}}
                                >
                                    <img 
                                        src={require("../images/add.png")}
                                        alt="plus"
                                    />    
                                    <span className="headerText">Add Crop</span>
                                </div>
                            :
                                props.modalIsOpen &&
                                    <div
                                        className="cropTitleContainer1"
                                        onClick={()=>{setStep1Modal(true)}}
                                    >
                                        <img 
                                            src={require("../images/add.png")}
                                            alt="plus"
                                        />    
                                        <span className="headerText">Add Crop</span>
                                    </div>
                        :
                            null
            }

            {
                props.selected_crop_id !== "" ?
                        <div className="cropTitleContainer">
                            {
                                Object.values(props.selected_crop_detail).length > 0 &&
                                    props.selected_crop_id !== "" &&
                                        props.selected_crop_id !== 0 &&
                                            <>
                                                {
                                                    "image" in props.selected_crop_detail[0] === true &&
                                                        props.selected_crop_detail[0].image !== null ?
                                                            <img 
                                                                src={`data:image/png;base64, ${props.selected_crop_detail[0].image}`}
                                                                alt="crop"
                                                                className="headerImage"
                                                            />
                                                        :
                                                            <div className="headerImage"></div>
                                                }
                                                {
                                                    (cropStatus !== "" && cropStatus === true)  &&
                                                        <div className="published"></div>
                                                }
                                                
                                                {
                                                    props.selected_crop_id.length !== 0 ?
                                                        <span className="headerText">
                                                            {
                                                                props.selected_crop_detail[0].crop_text.length > 0 ?
                                                                    langContent in props.selected_crop_detail[0].crop_text === true ?
                                                                        props.selected_crop_detail[0].crop_text[getEnLanguageIndex(props.selected_crop_detail[0].crop_text)].displayName
                                                                    :
                                                                        undefined
                                                                :
                                                                    undefined
                                                            }
                                                        </span>
                                                    :
                                                        <span className="headerText">
                                                            Generic Crop
                                                        </span>
                                                }
                                                {
                                                    window.location.pathname.includes("/crop/crop_calendars/media") === true &&
                                                        langContent in props.selected_crop_calendar.activity[0].activity_text &&
                                                            <span className="headerText">
                                                                {'/'}&nbsp;&nbsp;&nbsp;&nbsp; {props.selected_crop_calendar.activity[0].activity_text[langContent].name}
                                                            </span>
                                                } 
                                            </>
                            }
                        </div>
                    :
                        <div className={(props.selected_crop_id === "" || props.selected_crop_id === 0) ? "cropTitleContainer2" : "cropTitleContainer1"}></div>
                
            }

            {
                props.displayLanguage === true || "displayLanguage" in props === false &&
                    <div className="cropLanguageContainer"> 
                        {
                            props.language_list.map((season_list,key)=>{
                                let tempData = season_list;
                                let splitted_language = tempData.locale.split("_");

                                return (
                                    <div 
                                        key={key}
                                        className="headerLanguage"
                                        onClick={()=>{
                                            changeLanguage(tempData.locale.slice(0,2))
                                        }}
                                    >
                                        <div className="headerLanguageTop">
                                            <span>{splitted_language[0].toUpperCase()}</span>
                                        </div>

                                        <div className={props.lang === tempData.locale.slice(0,2) ? 'headerLanguageBottom activeText' : 'headerLanguageBottom'}></div>
                                    </div>
                                )
                            })
                        }
                    </div>
            }

            <Step1Modal 
                isModalOpen={step1Modal}
                yesButtonClicked={yesButtonClicked}
                noButtonClicked={noButtonClicked}
            />

            <Step2Modal 
                isModalOpen={step2Modal}
                closeModal={closeModal}
            />  

        </div>
    )
}

const mapStateToProps = (state) => {
    return {
       lang: state.lang.language,
       selected_crop_id: state.crop.selected_crop_id,
       selected_crop_detail: state.crop.selected_crop_detail,
       user_role_type: state.auth.user_role_type,
       crop_list: state.crop.crop_list,
       selected_crop_calendar: state.cropCalendar.selected_crop_calendar_media,
       language_list: state.lang.language_list,
       selected_crop_list: state.crop.selected_crop_list,
       mapping_list: state.organization.mapping_list,
       auth: state.auth.organization,
    };
};

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                languageUpdate,
                clearCropDetail
            },
            dispatch
        )
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Header);