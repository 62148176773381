import ActivityMediaActionTypes from './actionTypes';

const INITIAL_STATE = {
    loading: false,
    activity_list: [],
    specific_crop_activity_list: [],
    specific_crop_activity_list_loading: false,
    specific_crop_activity_list1: [],
    specific_crop_activity_list_loading1: false,
    activity_register_loading: false,
    delete_activity_media_request: false,
    selected_activity_media: '',
    crop_specific_activity: [],
    all_media_list: [],
    all_media_list_loading: false,
    updateActivityMedia: 0,
    selected_generic_activity: ''
}

export default function(state=INITIAL_STATE, action){
    switch(action.type){
        case ActivityMediaActionTypes.CROP_ACTIVITY_MEDIA_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActivityMediaActionTypes.CROP_ACTIVITY_MEDIA_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                activity_list: action.activity_list,
            }
        case ActivityMediaActionTypes.SPECIFIC_CROP_ACTIVITY_LIST_REQUEST:
            return {
                ...state,
                specific_crop_activity_list_loading: true,
                selected_activity_media: action.data.activity_id,
                //specific_crop_activity_list1: [],
            }
        case ActivityMediaActionTypes.SPECIFIC_CROP_ACTIVITY_LIST_SUCCESS:
            return {
                ...state,
                specific_crop_activity_list_loading: false,
                specific_crop_activity_list: action.specific_crop_activity_list,
                selected_activity_media1: [],
                specific_crop_activity_list1: action.specific_crop_activity_list1,
            }
        case ActivityMediaActionTypes.GENERIC_CROP_ACTIVITY_LIST_REQUEST:
            return {
                ...state,
                specific_crop_activity_list_loading: true,
                selected_activity_media: action.data.activity_id,
                //specific_crop_activity_list1: []
            }
        case ActivityMediaActionTypes.GENERIC_CROP_ACTIVITY_LIST_SUCCESS:
            return {
                ...state,
                specific_crop_activity_list_loading: false,
                specific_crop_activity_list: action.specific_crop_activity_list,
                selected_activity_media1: []
            }
        case ActivityMediaActionTypes.SPECIFIC_CROP_ACTIVITY_LIST_REQUEST1:
            return {
                ...state,
                specific_crop_activity_list_loading1: true,
                selected_activity_media: action.data.activity_id,
                //specific_crop_activity_list: []
            }
        case ActivityMediaActionTypes.SPECIFIC_CROP_ACTIVITY_LIST_SUCCESS1:
            return {
                ...state,
                specific_crop_activity_list_loading1: false,
                specific_crop_activity_list1: action.specific_crop_activity_list,
                //all_media_list: []
            }
        case ActivityMediaActionTypes.ACTIVITY_MEDIA_REGISTER_REQUEST:
            return {
                ...state,
                activity_register_loading: true,
            }
        case ActivityMediaActionTypes.ACTIVITY_MEDIA_REGISTER_SUCCESS:
            return {
                ...state,
                activity_register_loading: false,
                updateActivityMedia: state.updateActivityMedia + 1,
            }
        case ActivityMediaActionTypes.REMOVE_SPECIFIC_ACTIVITY:
            return {
                ...state,
                specific_crop_activity_list: [],
            }
        case ActivityMediaActionTypes.DELETE_ACTIVITY_MEDIA_REQUEST:
            return {
                ...state,
                delete_activity_media_request: true
            }
        case ActivityMediaActionTypes.DELETE_ACTIVITY_MEDIA_SUCCESS:
            return {
                ...state,
                delete_activity_media_request: false
            }
        case ActivityMediaActionTypes.CLEAR_SELECTED_ACTIVITY_MEDIA:
            return {
                ...state,
                selected_activity_media: ''
            }
        case ActivityMediaActionTypes.CROP_SPECIFIC_ACTIVITY_SUCCESS:
            return {
                ...state,
                crop_specific_activity: action.crop_specific_activity
            }
        case ActivityMediaActionTypes.ALL_MEDIA_LIST_REQUEST:
            return {
                ...state,
                all_media_list_loading: true,
                //specific_crop_activity_list1: [],
                updateActivityMedia: 0
            }
        case ActivityMediaActionTypes.ALL_MEDIA_LIST_SUCCESS:
            return {
                ...state,
                all_media_list: action.all_media_list,
                all_media_list_loading: false,
            }
        case ActivityMediaActionTypes.ALL_GENERIC_MEDIA_LIST_REQUEST:
            return {
                ...state,
                all_media_list_loading: true,
                //specific_crop_activity_list1: [],
                updateActivityMedia: 0,
            }
        case ActivityMediaActionTypes.ALL_GENERIC_MEDIA_LIST_SUCCESS:
            return {
                ...state,
                all_media_list: action.all_media_list,
                all_media_list_loading: false,
            }
        case ActivityMediaActionTypes.SELECTED_GENERIC_ACTIVITY:
            return {
                ...state,
                selected_generic_activity: action.data,
            }
        case ActivityMediaActionTypes.REMOVE_SELECTED_GENERIC_ACTIVITY:
            return {
                ...state,
                selected_generic_activity: "",
            }
        default:
            return state;
    }
}