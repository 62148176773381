import ActivityActionTypes from './actionTypes';

const INITIAL_STATE = {
    loading: false,
    category_loading: false,
    generic_category: [],
    crop_specific_category: [],
    activity_list: [],
    activity_loading: false,
    activity_category_list: [],
    activity_category_list_loading: false,
}

export default function(state=INITIAL_STATE, action){
    switch(action.type){
        case ActivityActionTypes.ACTIVITY_REGISTER_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActivityActionTypes.ACTIVITY_REGISTER_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case ActivityActionTypes.CATEGORY_LIST_REQUEST:
            return {
                ...state,
                category_loading: true
            }
        case ActivityActionTypes.CATEGORY_LIST_SUCCESS:
            return {
                ...state,
                category_loading: false,
                generic_category: action.generic_category,
                crop_specific_category: action.crop_specific_category,
            }
        case ActivityActionTypes.ACTIVITY_LIST_REQUEST:
            return {
                ...state,
                activity_loading: true
            }
        case ActivityActionTypes.ACTIVITY_LIST_SUCCESS:
            return {
                ...state,
                activity_loading: false,
                activity_list: action.activity_list
            }
        case ActivityActionTypes.DELETE_ACTIVITY_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActivityActionTypes.DELETE_ACTIVITY_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case ActivityActionTypes.ACTIVITY_SORTING_LIST_REQUEST:
            return {
                ...state,
                activity_category_list_loading: true
            }
        case ActivityActionTypes.ACTIVITY_SORTING_LIST_SUCCESS:
            return {
                ...state,
                activity_category_list: action.activity_category_list,
                activity_category_list_loading: false
            }
        case ActivityActionTypes.REMOVE_ACTIVITIES:
            return {
                ...state,
                activity_category_list: [],
            }
        case ActivityActionTypes.UPDATE_ACTIVITY_STATUS_REQUEST:
            return {
                ...state,
                activity_loading: true
            }
        case ActivityActionTypes.UPDATE_ACTIVITY_STATUS_SUCCESS:
            return {
                ...state,
                activity_loading: false
            }
        default:
            return state;
    }
}