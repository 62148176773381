import LanguageActionTypes from './actionTypes';
import I18n from './i18n';

const initialState={
    language: 'en',
    language_loading: false,
    language_list: [],
};

export default function languageUpdate(state=initialState,action)
{
    switch(action.type)
    {
        case LanguageActionTypes.LANGUAGE_UPDATE:
            I18n.lng=action.language;

            return Object.assign({},state,{
                language:action.language,
                language_loading: true
            });
        
        case LanguageActionTypes.LANGUAGE_SUCCESS: 
            return {
                ...state,
                language_loading: action.language_loading
            }

        case LanguageActionTypes.LANGUAGE_LIST_REQUEST:
            return {
                ...state,
            }
        case LanguageActionTypes.LANGUAGE_LIST_SUCCESS:
            return {
                ...state,
                language_list: action.language_list
            }
        default:
          return state
    }
}