const AuthActionTypes = {
    AUTH_LOGIN_REQUEST: 'auth.login.request',
    AUTH_LOGIN_SUCCESS: 'auth.login.success',
    AUTH_LOGIN_FAIL: 'auth.login.fail',
    AUTH_LOGOUT_REQUEST: 'auth.logout.request',
    LOGIN_REFRESH: 'auth.login.refresh',
    USER_DETAIL_REQUEST: 'user_detail_request',
    USER_DETAIL_SUCCESS: 'user_detail_success',

    ORGANIZATION_SELECTION_REQUEST: 'organization_selection_request',
    ORGANIZATION_SELECTION_SUCCESS: 'organization_selection_success',

    ORGANIZATION_RESET_REQUEST: 'organization_reset_request',
    ORGANIZATION_RESET_SUCCESS: 'organization_reset_success',
}
export default AuthActionTypes;