import Api from "../../../../config/api";
import errorParse from '../../../lib/error';

function activityList(params) {
    const res = Api.get('/cropActivityList',{ params }).then((response)=>{
        let data = response.data;
        return data;
    }).catch((error)=>{
        return errorParse(error);
    });
    return res;
}

function genericActivityList(params) {
    const res = Api.get('/genericActivityList',{ params }).then((response)=>{
        let data = response.data;
        return data;
    }).catch((error)=>{
        return errorParse(error);
    });
    return res;
}

function registerCropCalendar(params) {
    const res = Api.post('/cropActivity',params).then((response)=>{
        let data = response.data;
        return data;
    }).catch((error)=>{
        return errorParse(error);
    });
    return res;
}

function deleteCropActivity(params) {
    const res = Api.get('/deleteCropActivity',{ params }).then((response)=>{
        let data = response.data;
        return data;
    }).catch((error)=>{
        return errorParse(error);
    });
    return res;
}

function genericActivities(params) {
    const res = Api.get('/genericActivities', {params}).then((response)=>{
        let data = response.data;
        return data;
    }).catch((error)=>{
        return errorParse(error);
    });
    return res;
}


function activityCalenderList(params) {
    const res = Api.get('/activityInCalender',{ params }).then((response)=>{
        let data = response.data;
        return data;
    }).catch((error)=>{
        return errorParse(error);
    });
    return res;
}


export const CropCalendarService = { 
    activityList,
    registerCropCalendar,
    genericActivityList,
    deleteCropActivity,
    genericActivities,
    activityCalenderList
}