import GrowAreaActionTypes from './actionTypes';

import { fork, take, call, put, delay } from "redux-saga/effects";

import { GrowAreaService } from "./service";

import history from '../../config/history';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function* growAreaRequest() {
    while(true){
        yield take(GrowAreaActionTypes.GROW_AREA_LIST_REQUEST);
        let data1 = {};
         
        data1.organisation = JSON.parse(localStorage.getItem('user')).organization;

        var grow_area_list = yield call(GrowAreaService.growAreaRequest, data1);
       
        if(grow_area_list){
            yield put({
                type: GrowAreaActionTypes.GROW_AREA_LIST_SUCCESS,
                grow_area_list: grow_area_list
            })
        }
        else{
            yield put({
                type: GrowAreaActionTypes.GROW_AREA_LIST_FAILURE,
            })
        }
    }
}

export function* registerGrowArea(){
    while(true){
        const { data } = yield take(GrowAreaActionTypes.REGISTER_GROW_AREA_REQUEST);

        var grow_area = yield call(GrowAreaService.registerGrowArea, data);

        if(grow_area === "Grow Areas are added"){
            yield put({
                type: GrowAreaActionTypes.REGISTER_GROW_AREA_SUCCESS,
            })

            let data1 = {};
         
            data1.organisation = JSON.parse(localStorage.getItem('user')).organization;

            var grow_area_list = yield call(GrowAreaService.growAreaRequest, data1);

            if(grow_area_list){
                toast.success("Grow area has been added/updated successfully", {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });

                yield put({
                    type: GrowAreaActionTypes.GROW_AREA_LIST_SUCCESS,
                    grow_area_list: grow_area_list
                })

                yield delay(2000);

                history.push(`/home`);
                history.push(`/grow_area`);
            }
            else{
                yield put({
                    type: GrowAreaActionTypes.GROW_AREA_LIST_FAILURE,
                })
            }
        }
        else{
            yield put({
                type: GrowAreaActionTypes.REGISTER_GROW_AREA_FAILURE,
            })
        }
    }
}

export function* deleteGrowAreaRequest(){
    while(true){
        const { grow_area_id } = yield take(GrowAreaActionTypes.DELETE_GROW_AREA_REQUEST);

        var delete_grow_area = yield call(GrowAreaService.deleteGrowAreaValues, grow_area_id);
        
        if(delete_grow_area === "Selected rows has been deleted."){
            yield put({
                type: GrowAreaActionTypes.DELETE_GROW_AREA_SUCCESS,
            })

            let data1 = {};
         
            data1.organisation = JSON.parse(localStorage.getItem('user')).organization;

            var grow_area_list = yield call(GrowAreaService.growAreaRequest, data1);

            if(grow_area_list){
                toast.success("Grow area has been deleted successfully", {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });


                yield put({
                    type: GrowAreaActionTypes.GROW_AREA_LIST_SUCCESS,
                    grow_area_list: grow_area_list
                })

                yield delay(2000);
                
                history.push(`/home`);
                history.push(`/grow_area`);
            }
            else{
                yield put({
                    type: GrowAreaActionTypes.GROW_AREA_LIST_FAILURE,
                })
            }
        }
    }
}

export default function* GrowAreaSaga(){
    yield fork(growAreaRequest);
    yield fork(registerGrowArea);
    yield fork(deleteGrowAreaRequest);
}