import React from 'react';
import {I18nextProvider} from 'react-i18next';
import i18n from './trans/i18n';
import {Provider} from 'react-redux';
import Approuter from './config/appRouter';
import store from './config/store';
import './App.css';

const App = props => {
  return (
    <div className="App">
      <I18nextProvider i18n={i18n}>
        <Provider store={store()}>
          <Approuter />
        </Provider>
      </I18nextProvider>
    </div>
  )
}

export default App;