import ScaleValueActionTypes from './actionTypes';

const INITIAL_STATE = {
   scale_type_list: {},
   scale_type_list_loading: false,
   scale_value_list: {},
   scale_value_list_loading: false,
   scale_value_add_loading: false,
   delete_scale_value_loading: false,
   register_scale_type_loading: false,
   delete_scale_type_loading: false
}

export default function(state = INITIAL_STATE, action){
    switch(action.type){
        case ScaleValueActionTypes.SCALE_TYPE_LIST_REQUEST:
            return {
                ...state,
                scale_type_list_loading: true
            }
        case ScaleValueActionTypes.SCALE_TYPE_LIST_SUCCESS:
            return {
                ...state,
                scale_type_list_loading: false,
                scale_type_list: action.scale_type_list
            }
        case ScaleValueActionTypes.SCALE_TYPE_LIST_FAILURE:
            return {
                ...state,
                scale_type_list_loading: false
            }

        case ScaleValueActionTypes.SCALE_VALUE_LIST_REQUEST:
            return {
                ...state,
                scale_value_list_loading: true
            }
        case ScaleValueActionTypes.SCALE_VALUE_LIST_SUCCESS:
            return {
                ...state,
                scale_value_list_loading: false,
                scale_value_list: action.scale_value_list
            }
        case ScaleValueActionTypes.SCALE_VALUE_LIST_FAILURE:
            return {
                ...state,
                scale_value_list_loading: false
            }
        case ScaleValueActionTypes.SCALE_VALUE_ADD_REQUEST:
            return {
                ...state,
                scale_value_add_loading: true
            }
        case ScaleValueActionTypes.SCALE_VALUE_ADD_SUCCESS:
        case ScaleValueActionTypes.SCALE_VALUE_ADD_FAILURE:
            return {
                ...state,
                scale_value_add_loading: false
            }

        case ScaleValueActionTypes.SCALE_VALUE_ADD_REQUEST:
            return {
                ...state,
                delete_scale_value_loading: true
            }
        case ScaleValueActionTypes.DELETE_SCALE_VALUE_SUCCES:
        case ScaleValueActionTypes.DELETE_SCALE_VALUE_FAILURE:
            return {
                ...state,
                delete_scale_value_loading: false
            }

        case ScaleValueActionTypes.REGISTER_SCALE_TYPE_REQUEST:
            return {
                ...state,
                register_scale_type_loading: true
            }
        case ScaleValueActionTypes.REGISTER_SCALE_TYPE_SUCCESS:
        case ScaleValueActionTypes.REGISTER_SCALE_TYPE_FAILURE:
            return {
                ...state,
                register_scale_type_loading: false
            }

        case ScaleValueActionTypes.DELETE_SCALE_TYPE_REUEST:
            return {
                ...state,
                delete_scale_type_loading: true
            }

        case ScaleValueActionTypes.DELETE_SCALE_TYPE_SUCCESS:
        case ScaleValueActionTypes.DELETE_SCALE_TYPE_FAILURE:
            return {
                ...state,
                delete_scale_type_loading: false
            }
        default: 
            return state;
    }
}