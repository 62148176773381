import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { languageUpdate } from '../trans/actions';
import './styles.css';

const Radio = props => {
    return (
        <div className="TextBoxContainer">
            <div className="leftLabelContainer">
               
            </div>

            <div className="rightLabelContainer">
                <label className="radio">
                    <span className="labelText" style={{marginRight: "10px"}}>{props.label1}</span>
                    <input type="radio" name={props.name} />
                    <span className="radioCheckmark"></span>
                </label>

                <label className="radio">
                    <span className="labelText">{props.label2}</span>
                    <input type="radio" name={props.name} />
                    <span className="radioCheckmark"></span>
                </label>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
       lang: state.lang.language
    };
};

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                languageUpdate
            },
            dispatch
        )
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Radio);