import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { languageUpdate } from '../trans/actions';
import './styles.css';

const FileType = props => {
    return (
        <div className="TextBoxContainer">
            <div className={props.activity === true ? "leftLabelContainer2" : "leftSelectLabelContainer"}>
                <span>{props.label}</span>
            </div>

            <div className={props.activity === true ? "rightLabelContainer" : "rightSelectLabelContainer"}>
                {
                    props.file &&
                        <img src={`data:image/png;base64, ${props.file}`} width={props.activity === true ? "30" : "50"} height={props.activity === true ? "30" : "50"} style={{objectFit: 'cover',paddingRight: '7px',marginTop: "-5px"
                    }} />
                }
                <div style={{display: "flex",flexDirection: 'column'}}>
                    {
                        props.error &&
                            <span className="errorText">{props.error}</span>
                    }
                    {
                        props.error1 &&
                            <span className="errorText">{props.error1}</span>
                    }
                    {
                        <input 
                            type="file"
                            accept="image/*"
                            className="custom-file-input"
                            onChange={(e)=>{props.handleChange(e)}}
                        />
                    }
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
       lang: state.lang.language
    };
};

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                languageUpdate
            },
            dispatch
        )
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FileType);