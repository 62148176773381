import RoleActionTypes from './actionTypes';

const INITIAL_STATE = {
    role_list: {},
    loading: false,
    selected_role: {},
    role_add_loading: {},
    role: {},
    role_loading: false,
    selected_particular_role_assigned: {},
    role_message: "",
    delete_data_loading: false,
}

export default function(state = INITIAL_STATE, action){
    switch(action.type){
        case RoleActionTypes.ROLE_LIST_REQUEST:
            return {
                ...state,
                loading: true,
                role_message: ""
            }
            case RoleActionTypes.ROLE_LIST_SUCCESS:
                return {
                    ...state,
                    role_list: action.role_list,
                    loading: false
                }
            case RoleActionTypes.ROLE_LIST_FAILURE:
                return {
                    ...state,
                    role_list: {},
                    loading: false
                }
            case RoleActionTypes.ROLE_SELECT_REQUEST:
                return {
                    ...state,
                    selected_role: action.selected_role
                }
            case RoleActionTypes.ROLE_ADD_REQUEST:
                return {
                    ...state,
                    role_add_loading: true,
                    role_message: ""
                }
            case RoleActionTypes.ROLE_ADD_SUCCESS:
                return {
                    ...state,
                    role_add_loading: false,
                    role_message: action.role_message
                }
            case RoleActionTypes.ROLE_ADD_FAILURE:
                return {
                    ...state,
                    role_add_loading: false,
                    role_message: action.role_message
                }
            case RoleActionTypes.ROLE_REQUEST:
                return {
                    ...state,
                    role_loading: true
                }
            case RoleActionTypes.ROLE_SUCCESS:
                return {
                    ...state,
                    role_loading: false,
                    role: action.role
                }
            case RoleActionTypes.ROLE_FAILURE:
                return {
                    ...state,
                    role_loading: false,
                    role: {}
                }
            case RoleActionTypes.SELECT_PARTICULAR_ROLE_ASSIGNED_REQUEST:
                return {
                    ...state,
                    selected_particular_role_assigned: action.data
                }
            case RoleActionTypes.DELETE_ROLE_REQUEST:
                return {
                    ...state,
                    delete_data_loading: true
                }
            case RoleActionTypes.DELETE_ROLE_SUCCESS:
                return {
                    ...state,
                    delete_data_loading: false
                }
        default: 
            return state;
    }
}