import CropCalendarActionTypes from './actionTypes';

const INITIAL_STATE = {
    loading: false,
    crop_calendar_activity: [],
    selected_crop_calendar_media: [],
    register_crop_calendar_loading: false,
    delete_crop_calendar_loading: false,
    genericActivities: [],
    activityCalenderList: [],
}

export default function(state=INITIAL_STATE, action){
    switch(action.type){
        case CropCalendarActionTypes.CROP_CALENDAR_ACTIVITY_REQUEST:
            return {
                ...state,
                loading: true
            }
        case CropCalendarActionTypes.CROP_CALENDAR_ACTIVITY_SUCCESS:
            return {
                ...state,
                loading: false,
                crop_calendar_activity: action.crop_calendar_activity,
            }
        case CropCalendarActionTypes.SELECTED_CROP_CALENDAR_MEDIA:
            return {
                ...state,
                selected_crop_calendar_media: action.data
            }
        case CropCalendarActionTypes.CROP_CALENDAR_ACTIVITY_EDIT:
            return {
                ...state,
                selected_crop_calendar_media: action.data
            }
        case CropCalendarActionTypes.REGISTER_CROP_CALENDAR_ACTIVITY_REQUEST:
            return {
                ...state,
                register_crop_calendar_loading: true
            }
        case CropCalendarActionTypes.REGISTER_CROP_CALENDAR_ACTIVITY_SUCCESS:
            return {
                ...state,
                register_crop_calendar_loading: false
            }
        case CropCalendarActionTypes.DELETE_ACTIVITY_MEDIA_REQUEST: 
            return {
                ...state,
                delete_crop_calendar_loading: true
            }
        case CropCalendarActionTypes.DELETE_ACTIVITY_MEDIA_SUCCESS: 
            return {
                ...state,
                delete_crop_calendar_loading: false
            }
        case CropCalendarActionTypes.GENERIC_ACTIVITIES_SUCCESS:
            return {
                ...state,
                genericActivities: action.genericActivities
            }
        case CropCalendarActionTypes.ACTIVITY_CALENDAR_LIST_SUCCESS:
            return {
                ...state,
                activityCalenderList: action.activityCalenderList
            }
        default:
            return state;
    }
}