import ScaleValueActionTypes from './actionTypes';

export function scaleTypeRequest(org){
    return {
        type: ScaleValueActionTypes.SCALE_TYPE_LIST_REQUEST,
        org
    }
}

export function scaleValueRequest(data){
    return {
        type: ScaleValueActionTypes.SCALE_VALUE_LIST_REQUEST,
        data
    }
}

export function addScaleValue(data){
    return {
        type: ScaleValueActionTypes.SCALE_VALUE_ADD_REQUEST,
        data
    }
}

export function deleteScaleValues(data){
    return {
        type: ScaleValueActionTypes.DELETE_SCALE_VALUE_REQUEST,
        data
    }
}

export function registerScaleTypeRequest(data){
    return {
        type: ScaleValueActionTypes.REGISTER_SCALE_TYPE_REQUEST,
        data
    }
}

export function deleteScaleType(data){
    return {
        type: ScaleValueActionTypes.DELETE_SCALE_TYPE_REUEST,
        data
    }
}
