import OrganizationActionTypes from './actionTypes';

const INITIAL_STATE = {
   organization_list: {},
   organization_list_loading: false,
   selected_organization: [],
   organisation_register_loading: false,
   organisation_delete_loading: false,
   countries: [],
   countries_list_loading: false,
   organisation_list: [],
   organisation_list_loading: false,
   selected_organization_loading: false,
   mapping_list: [],
   mapping_list_loading: false,
   country: [],
   country_loading: false,
}

export default function(state = INITIAL_STATE, action){
    switch(action.type){
        case OrganizationActionTypes.ORGANIZATION_LIST_REQUEST:
            return {
                ...state,
                organization_list_loading: true
            }
        case OrganizationActionTypes.ORGANIZATION_LIST_SUCCESS:
            return {
                ...state,
                organization_list_loading: false,
                organization_list: action.organization_list
            }
        case OrganizationActionTypes.ORGANIZATION_LIST_FAILURE:
            return {
                ...state,
                organization_list_loading: false,
                organization_list: {}
            }
        case OrganizationActionTypes.ORGANIZATION_SELECT_REQUEST:
            return {
                ...state,
                selected_organization: [],
                selected_organization_loading: true,
            }
        case OrganizationActionTypes.ORGANIZATION_SELECT_SUCCESS:
            return {
                ...state,
                selected_organization: action.selected_organization,
                selected_organization_loading: false,
            }
        case OrganizationActionTypes.ORGANIZATION_OFFLINE_REQUEST:
            return {
                ...state,
                organization_list: action.data,
                organization_list_loading: true,
            }
        case OrganizationActionTypes.ORGANIZATION_OFFLINE_SUCCESS:
            return {
                ...state,
                organization_list_loading: false,
            }
        case OrganizationActionTypes.ORGANIZATION_ADD_REQUEST:
            return {
                ...state,
                organisation_register_loading: true
            }
        case OrganizationActionTypes.ORGANIZATION_ADD_SUCCESS:
            return {
                ...state,
                organisation_register_loading: false,
                selected_organization: [],
            }
        case OrganizationActionTypes.ORGANIZATION_DELETE_REQUEST:
            return {
                ...state,
                organisation_delete_loading: true,
                selected_organization: [],
            }
        case OrganizationActionTypes.ORGANIZATION_DELETE_SUCCESS:
            return {
                ...state,
                organisation_delete_loading: false
            }
        case OrganizationActionTypes.REMOVE_SELECTED_ORGANISATION:
            return {
                ...state,
                selected_organization: []
            }
        case OrganizationActionTypes.COUNTRY_LIST_REQUEST:
            return {
                ...state,
                countries_list_loading: true
            }
        case OrganizationActionTypes.COUNTRY_LIST_SUCCESS:
            return {
                ...state,
                countries: action.countries,
                countries_list_loading: false
            }
        case OrganizationActionTypes.ORGANISATION_LIST_REQUEST:
            return {
                ...state,
                organisation_list_loading: true
            }
        case OrganizationActionTypes.ORGANISATION_LIST_SUCCESS:
            return {
                ...state,
                organisation_list_loading: false,
                organisation_list: action.organisation_list,
            }
        case OrganizationActionTypes.ORGANISATION_LOCALE_REQUEST:
            return {
                ...state
            }
        case OrganizationActionTypes.ORGANISATION_LOCALE_SUCCESS:
            return {
                ...state,
                selected_organization: action.selected_organization
            }
        case OrganizationActionTypes.ORAGNISATION_MAPPING_LIST_REQUEST:
            return {
                ...state,
                mapping_list_loading: true
            }
        case OrganizationActionTypes.ORAGNISATION_MAPPING_LIST_SUCCESS:
            return {
                ...state,
                mapping_list: action.mapping_list,
                mapping_list_loading: false
            }
        case OrganizationActionTypes.COUNTRY_REQUEST:
            return {
                ...state,
                country_loading: true
            }
        case OrganizationActionTypes.COUNTRY_SUCCESS:
            return {
                ...state,
                country: action.country,
                country_loading: false
            }
        case OrganizationActionTypes.ORGANIZATION_REQUEST:
            return {
                ...state,
                organization_list_loading: true
            }
        case OrganizationActionTypes.ORGANIZATION_SUCCESS:
            return {
                ...state,
                organization_list_loading: false,
                organization_list: action.organization_list
            }
        default: 
            return state;
    }
}